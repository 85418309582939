import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useEffectOnce} from "react-use";
import {MediaRecorder, register} from "extendable-media-recorder";
import {connect} from "extendable-media-recorder-wav-encoder";

let mediaRecorder, stream, audioChunks;

export type RecordType = {
  file: File;
  url: string;
};

export const useRecord = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [recording, setRecording] = useState(false);
  const [record, setRecord] = useState<RecordType | null>(null);
  const [timer, setTimer] = useState<number>(0);
  const {t} = useTranslation();

  // On start record
  const onStartRecord = async () => {
    try {
      // Actions on start record
      audioChunks = [];
      setRecord(null);

      const isNotHasAudioInput = await onCheckAudioInput();

      if (isNotHasAudioInput) {
        toast.error(t("audioInputConnection"));
        return;
      }

      stream = await navigator.mediaDevices.getUserMedia({audio: true});

      mediaRecorder = new MediaRecorder(stream, {mimeType: "audio/wav"});

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks = [...audioChunks, event.data];
        }
      };

      mediaRecorder.onstop = () => {
        onGenerateRecord();
      };

      mediaRecorder.start();
      setRecording(true);
    } catch (err) {
      console.error("Error starting recording:", err);
      toast.error(t("recordStartError"));
    }
  };

  useEffectOnce(() => {
    // Initialize encoder and register with media recorder
    (async () => {
      try {
        // Connect and register WAV encoder
        const wavEncoder = await connect();
        await register(wavEncoder);
      } catch (err) {
        console.error("Error initializing encoder:", err);
        toast.error(t("encoderInitError"));
      }
    })();
  });

  // On end record
  const onEndRecord = () => {
    mediaRecorder.stop();
    stream.getTracks().forEach((track) => track.stop());
    setRecording(false);
    setTimer(0); // Reset timer on delete record
  };

  // On generate record attachments
  const onGenerateRecord = () => {
    const audioBlob = new Blob(audioChunks, {type: "audio/wav"});
    const url = URL.createObjectURL(audioBlob);

    const file = new File([audioBlob], `record-${Date.now()}.wav`, {
      type: "audio/wav",
    });

    setRecord({
      file,
      url,
    });
  };

  // On delete record
  const onDeleteRecord = (e) => {
    e.stopPropagation();
    audioChunks = [];
    setRecord(null);
    setTimer(0); // Reset timer on delete record
  };

  // On record action
  const onRecordAction = () => {
    if (recording) {
      onEndRecord();
    } else {
      onStartRecord();
    }
  };

  // On check audio input
  const onCheckAudioInput = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const hasMicrophone = devices.some(
      (device) => device.kind === "audioinput" || device.kind === "videoinput"
    );

    setIsDisabled(!hasMicrophone);

    return !hasMicrophone;
  };

  // Update timer every second when recording
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (recording) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [recording]);

  // Format timer as "00:00"
  const formattedTimer = new Date(timer * 1000).toISOString().substr(11, 8);

  return {
    recording,
    onRecordAction,
    onDeleteRecord,
    record,
    timer: formattedTimer,
    isDisabled,
  };
};