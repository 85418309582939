import React, {useEffect, useState} from 'react'
import {ApiService, makeRequest} from '../../services/data.service'
import {useTranslation} from 'react-i18next'

export type ISettings = {
  logo?: string
  website_name?: string
  primary_color: string
  secondary_color: string
  activate_color: string
}

export const Vision = () => {
  const {t} = useTranslation()

  const [settingsData, setSettingsData] = useState<ISettings>()
  const [visionData, setVisionData] = useState<any>()

  useEffect(() => {
    ApiService.Settings()
      .then((response) => {
        setSettingsData(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
    makeRequest.ApiService('media/home')
      .then((response: any) => {
        setVisionData(response.data?.vision_section)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }, [])

  return (
    <React.Fragment>
      <div
        id="whysm"
        className={`tcon-why-us-wrapper py-15 sm:py-32 bg-[${
          settingsData && settingsData.secondary_color
        }]`}
      >
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {t('goalsvision')}
            </h2>
          </div>
          <div className="mx-auto my-16 lg:mt-16">
            <dl className="grid max-w-xl grid-cols-1 gap-x-10 gap-y-10 md:max-w-none md:grid-cols-2 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
              {visionData &&
                visionData.map((item: any, index: number) => (
                  <dt className="text-start leading-7 text-white" key={index}>
                    <h2 className="mb-6 mt-2 text-start text-3xl font-bold tracking-tight text-white sm:text-4xl">
                      {item.title}
                    </h2>
                    <ul className="list-disc">
                      <li className="text-lg font-semibold" key={index}>
                        {item.description}
                      </li>
                    </ul>
                  </dt>
                ))}
            </dl>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
