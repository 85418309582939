export const persistMyInfo = (payload) => {
    window.localStorage.setItem('id_token', payload.token)
    window.localStorage.setItem('id_user', payload.user.id)
    window.localStorage.setItem('phone_user', payload.user.phone)
    window.localStorage.setItem('isAuthenticated', true)
}

export const unpersistMyInfo = () => {
    window.localStorage.removeItem('id_token')
    window.localStorage.removeItem('id_user')
    window.localStorage.removeItem('ticket_id')
    window.localStorage.removeItem('order_id')
    window.localStorage.removeItem('order_type')
    window.localStorage.removeItem('phone_user')
    window.localStorage.removeItem('isAuthenticated')
}