import React, {useEffect, useState} from 'react'
import {ApiService} from '../../services/data.service'
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Tickets = () => {
  const {t} = useTranslation()
  const [ticketsData, setTicketsData] = useState<any>()

  useEffect(() => {
    ApiService.GetuserComplaint()
      .then((response) => {
        setTicketsData(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb title={t('tickets')} />
        <div className="tcon-about-section py-20 min-h-screen">
          <div className="rounded-md border">
            {ticketsData &&
              ticketsData.map((ticket: any, index: number) => (
                <Link
                  key={index}
                  to={`/tickets/${ticket?.order?.id}/${ticket.id}`}
                  state={{ id: ticket.id }}
                  className="rw_offer_item"
                  onClick={() => {
                    localStorage.setItem('ticket_id', ticket.id);
                    localStorage.setItem('order_id', ticket.order_id);
                  }}
                >
                  <div className="rw_offer_rt">
                    <div className="offer_txt_rw">
                      <div className="order_num">{t('ticket')} #{ticket.id}</div>
                      <p>{ticket?.order?.sub_service?.name} - {ticket?.order?.sub_service?.main_service_name}</p>
                      <p>#{ticket?.order?.sub_service?.id}</p>
                      <p></p>
                      <ul className="offer_meta clearfix">
                        <li>
                          <i className="fa fa-user"></i> {ticket?.order?.provider?.user_account_type} : {ticket?.order?.provider?.name}
                        </li>
                        <li>
                          <i className="fa fa-clock"></i>{ticket?.order.time}
                        </li>
                        <li>
                          <i className="fa fa-calendar-alt"></i> {ticket?.order.media_date}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="rw_offer_lt ms-auto">
                    <div className="rw_offer_lt_cn" data-status="Opened">
                      <div className="state_order">{ticket.status}</div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
        {/* <div className="tcon-about-section py-40">
            <div className="relative xl:container isolate mx-auto">
                <div className="flex flex-wrap items-start justify-between p-6 lg:px-8 items-center">
                    <div className="flex-2 lg:flex-1 w-100 lg:w-50 items-center tcon-about-img">
                        <img className="pe-0 lg:pe-8 max-w-xs lg:max-w-xl mx-auto" src={aboutData && aboutData.image} alt='' />
                    </div>
                    <div className="flex-1 w-100 lg:w-50 mt-10 lg:mt-0 md:mr-5">
                        <div className="text-start">
                            <h1 className="text-3xl font-bold tracking-tight sm:text-6xl">{aboutData && aboutData.name}</h1>
                            <p className="mt-6 text-2xl leading-8">{aboutData && aboutData.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* <div className="tcon-about-section py-40">
            <div className="relative xl:container isolate mx-auto">
                <div className="flex items-start justify-between p-6 lg:px-8 items-center">
                    <div className="flex-1 w-50 items-center tcon-about-img">
                        <img className="pe-8 mx-auto" src={aboutData && aboutData.image} alt='' />
                    </div>
                    <div className="flex-1 w-50">
                        <div className="text-start">
                            <h1 className="text-3xl font-bold tracking-tight sm:text-6xl">{aboutData && aboutData.name}</h1>
                            <p className="mt-6 text-2xl leading-8">{aboutData && aboutData.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
      </div>
      <Footer />
    </div>
  )
}

export default Tickets
