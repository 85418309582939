import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ApiService} from '../../services/data.service'
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import {store} from '../../reducers'
import {Link} from 'react-router-dom'

import ordersImg from '../../assets/images/icon/orders.svg'
import {ServicesItem} from '../Services/ServicesType'

export const Profile = () => {
  const {t} = useTranslation()
  const [contentData, setContentData] = useState<any>()
  const user_id = store.getState().authStoreState.id
  const [servicesData, setServicesData] = useState<ServicesItem>()

  useEffect(() => {
    ApiService.getProfile({
      id: user_id,
    })
      .then((response) => {
        setContentData(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
    ApiService.Services()
      .then((response) => {
        setServicesData(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  console.log(servicesData)

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb title={t('profile')} />
        <div className="tcon-about-section py-20">
          <div className="xs:grid-cols-1 grid items-center gap-x-8 px-8 py-10 sm:grid-cols-1 lg:grid-cols-3">
            <Link
              to={'/profile/edit'}
              className="box_link_item balance_item_two height_full"
            >
              <div className="user_thumb">
                <span>
                  <img
                    className="w-6"
                    src={contentData?.image}
                    alt={contentData?.name}
                  />
                </span>
              </div>
              <div className="user_txt">
                <h2>
                  #{contentData?.id} - {contentData?.name}
                </h2>
              </div>
            </Link>
            <Link
              to={''}
              className="box_link_item flex_wrp balance_item_two height_full"
            >
              <div className="link_lg">
                <ul>
                  <p className="mb-6">{t('orderCount')}</p>
                  {servicesData &&
                    servicesData.map((item: any, index: number) => (
                      item &&
                      item.subServices.map((sub: any, index: number) => (
                        sub.limit !== 0 &&
                      <li key={index} id={sub.id} className="flex justify-between items-center gap-14">
                        <p className="text-sm">{sub.name}</p>
                        <p className="text-sm">{sub.limit}</p> 
                      </li>
                      ))
                    ))}
                </ul>
              </div>
            </Link>
            <div className="">
              <Link
                to={'/my_orders'}
                className="box_link_item flex_wrp bg_color1 height_full"
              >
                <svg
                  id="Layer_2"
                  width="30"
                  height="50"
                  fill="#FFF"
                  data-name="Layer 2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 17.5 21.5"
                >
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M13.41,2.29c-.13-.53-.39-1.02-.79-1.41-.57-.57-1.32-.88-2.12-.88h-3.5c-1.41,0-2.59,.98-2.91,2.29C1.39,2.51,0,4.08,0,7v9.75c0,3.02,1.73,4.75,4.75,4.75H12.75c3.02,0,4.75-1.73,4.75-4.75V7c0-2.92-1.39-4.49-4.09-4.71Zm-6.41-.79h3.5c.4,0,.78,.16,1.06,.44,.28,.28,.44,.66,.44,1.06,0,.83-.67,1.5-1.5,1.5h-3.5c-.4,0-.78-.16-1.06-.44-.28-.28-.44-.66-.44-1.06,0-.83,.67-1.5,1.5-1.5Zm9,15.25c0,2.22-1.03,3.25-3.25,3.25H4.75c-2.22,0-3.25-1.03-3.25-3.25V7c0-2.39,1.02-3.09,2.6-3.23,.01,.04,.04,.08,.05,.12,.05,.17,.12,.33,.2,.48,.03,.06,.06,.12,.1,.18,.12,.2,.26,.39,.43,.56,.57,.57,1.32,.88,2.12,.88h3.5c.19,0,.38-.02,.56-.06,.06-.01,.11-.03,.17-.04,.12-.03,.24-.07,.36-.11,.06-.02,.12-.05,.18-.08,.11-.05,.21-.1,.3-.17,.06-.04,.11-.07,.17-.11,.1-.07,.19-.15,.28-.23,.04-.04,.09-.08,.13-.12,.11-.11,.21-.23,.3-.36,.01-.02,.03-.03,.04-.05,.1-.15,.18-.31,.26-.47,.02-.05,.04-.1,.06-.16,.03-.09,.07-.17,.1-.27,1.58,.13,2.6,.84,2.6,3.23v9.75ZM4,11.75c0-.41,.34-.75,.75-.75h4c.41,0,.75,.34,.75,.75s-.34,.75-.75,.75H4.75c-.41,0-.75-.34-.75-.75Zm9.5,4c0,.41-.34,.75-.75,.75H4.75c-.41,0-.75-.34-.75-.75s.34-.75,.75-.75H12.75c.41,0,.75,.34,.75,.75Z" />
                  </g>
                </svg>
                <span>{t('myorders')}</span>
              </Link>
            </div>
            {/* <div className="flex flex-col">
              <Link
                to={'/contact-us'}
                className="box_link_item flex_wrp bg_color1"
              >
                <svg
                  id="Layer_2"
                  width="30"
                  height="50"
                  fill="#FFF"
                  data-name="Layer 2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 17.5 21.5"
                >
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M13.41,2.29c-.13-.53-.39-1.02-.79-1.41-.57-.57-1.32-.88-2.12-.88h-3.5c-1.41,0-2.59,.98-2.91,2.29C1.39,2.51,0,4.08,0,7v9.75c0,3.02,1.73,4.75,4.75,4.75H12.75c3.02,0,4.75-1.73,4.75-4.75V7c0-2.92-1.39-4.49-4.09-4.71Zm-6.41-.79h3.5c.4,0,.78,.16,1.06,.44,.28,.28,.44,.66,.44,1.06,0,.83-.67,1.5-1.5,1.5h-3.5c-.4,0-.78-.16-1.06-.44-.28-.28-.44-.66-.44-1.06,0-.83,.67-1.5,1.5-1.5Zm9,15.25c0,2.22-1.03,3.25-3.25,3.25H4.75c-2.22,0-3.25-1.03-3.25-3.25V7c0-2.39,1.02-3.09,2.6-3.23,.01,.04,.04,.08,.05,.12,.05,.17,.12,.33,.2,.48,.03,.06,.06,.12,.1,.18,.12,.2,.26,.39,.43,.56,.57,.57,1.32,.88,2.12,.88h3.5c.19,0,.38-.02,.56-.06,.06-.01,.11-.03,.17-.04,.12-.03,.24-.07,.36-.11,.06-.02,.12-.05,.18-.08,.11-.05,.21-.1,.3-.17,.06-.04,.11-.07,.17-.11,.1-.07,.19-.15,.28-.23,.04-.04,.09-.08,.13-.12,.11-.11,.21-.23,.3-.36,.01-.02,.03-.03,.04-.05,.1-.15,.18-.31,.26-.47,.02-.05,.04-.1,.06-.16,.03-.09,.07-.17,.1-.27,1.58,.13,2.6,.84,2.6,3.23v9.75ZM4,11.75c0-.41,.34-.75,.75-.75h4c.41,0,.75,.34,.75,.75s-.34,.75-.75,.75H4.75c-.41,0-.75-.34-.75-.75Zm9.5,4c0,.41-.34,.75-.75,.75H4.75c-.41,0-.75-.34-.75-.75s.34-.75,.75-.75H12.75c.41,0,.75,.34,.75,.75Z" />
                  </g>
                </svg>
                <span>{t('myorders')}</span>
              </Link>
              <Link
                to={'/about-us'}
                className="box_link_item flex_wrp bg_color1"
              >
                <svg
                  id="faqs"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="50"
                  viewBox="0 0 70 60"
                >
                  <path
                    id="Path_456"
                    data-name="Path 456"
                    d="M6.031,6.461A17.234,17.234,0,0,1,18.49,1.68H48.472A17.234,17.234,0,0,1,60.93,6.461a17.234,17.234,0,0,1,4.781,12.459V36.909A17.235,17.235,0,0,1,60.93,49.367a16.665,16.665,0,0,1-10.21,4.664v4.254a5.238,5.238,0,0,1-8.141,4.361m0,0L29.8,54.148H18.49A17.234,17.234,0,0,1,6.031,49.367,17.235,17.235,0,0,1,1.25,36.909V31.3a2.249,2.249,0,1,1,4.5,0v5.607a12.757,12.757,0,0,0,3.464,9.279,12.758,12.758,0,0,0,9.278,3.464H30.482a2.248,2.248,0,0,1,1.245.377L45.07,58.9h0a.74.74,0,0,0,1.151-.617V51.9a2.249,2.249,0,0,1,2.249-2.249,12.758,12.758,0,0,0,9.279-3.464,12.758,12.758,0,0,0,3.464-9.279V18.919A12.758,12.758,0,0,0,57.75,9.641a12.757,12.757,0,0,0-9.279-3.464H18.49A12.757,12.757,0,0,0,9.211,9.641a12.757,12.757,0,0,0-3.464,9.278,2.249,2.249,0,1,1-4.5,0A17.235,17.235,0,0,1,6.031,6.461"
                    transform="translate(-1.25 -1.68)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_457"
                    data-name="Path 457"
                    d="M9.59,13.335a7.226,7.226,0,1,1,14.451,0,6.805,6.805,0,0,1-3.434,5.751,5.044,5.044,0,0,0-1.2,1.046,1.684,1.684,0,0,0-.341,1.088v.63a2.249,2.249,0,1,1-4.5,0v-.63a6.905,6.905,0,0,1,3.49-5.838l.01-.007h0a4.711,4.711,0,0,0,1.151-1,1.616,1.616,0,0,0,.327-1.037,2.728,2.728,0,1,0-5.457,0,2.249,2.249,0,1,1-4.5,0Z"
                    transform="translate(15.415 7.172)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_458"
                    data-name="Path 458"
                    d="M11.246,15.249A2.249,2.249,0,0,1,13.494,13h.027a2.249,2.249,0,0,1,0,4.5h-.027A2.249,2.249,0,0,1,11.246,15.249Z"
                    transform="translate(18.723 20.941)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                </svg>
                <span>{t('faq')}</span>
              </Link>
            </div> */}
          </div>
          <div className="xs:grid-cols-1 grid items-center gap-x-8 px-8 sm:grid-cols-1 lg:grid-cols-3">
            <Link
              to={'/contact-us'}
              className="box_link_item flex_wrp bg_color1"
            >
              <svg
                id="faqs"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="50"
                viewBox="0 0 70 60"
              >
                <path
                  id="Path_456"
                  data-name="Path 456"
                  d="M6.031,6.461A17.234,17.234,0,0,1,18.49,1.68H48.472A17.234,17.234,0,0,1,60.93,6.461a17.234,17.234,0,0,1,4.781,12.459V36.909A17.235,17.235,0,0,1,60.93,49.367a16.665,16.665,0,0,1-10.21,4.664v4.254a5.238,5.238,0,0,1-8.141,4.361m0,0L29.8,54.148H18.49A17.234,17.234,0,0,1,6.031,49.367,17.235,17.235,0,0,1,1.25,36.909V31.3a2.249,2.249,0,1,1,4.5,0v5.607a12.757,12.757,0,0,0,3.464,9.279,12.758,12.758,0,0,0,9.278,3.464H30.482a2.248,2.248,0,0,1,1.245.377L45.07,58.9h0a.74.74,0,0,0,1.151-.617V51.9a2.249,2.249,0,0,1,2.249-2.249,12.758,12.758,0,0,0,9.279-3.464,12.758,12.758,0,0,0,3.464-9.279V18.919A12.758,12.758,0,0,0,57.75,9.641a12.757,12.757,0,0,0-9.279-3.464H18.49A12.757,12.757,0,0,0,9.211,9.641a12.757,12.757,0,0,0-3.464,9.278,2.249,2.249,0,1,1-4.5,0A17.235,17.235,0,0,1,6.031,6.461"
                  transform="translate(-1.25 -1.68)"
                  fill="#fff"
                  fillRule="evenodd"
                />
                <path
                  id="Path_457"
                  data-name="Path 457"
                  d="M9.59,13.335a7.226,7.226,0,1,1,14.451,0,6.805,6.805,0,0,1-3.434,5.751,5.044,5.044,0,0,0-1.2,1.046,1.684,1.684,0,0,0-.341,1.088v.63a2.249,2.249,0,1,1-4.5,0v-.63a6.905,6.905,0,0,1,3.49-5.838l.01-.007h0a4.711,4.711,0,0,0,1.151-1,1.616,1.616,0,0,0,.327-1.037,2.728,2.728,0,1,0-5.457,0,2.249,2.249,0,1,1-4.5,0Z"
                  transform="translate(15.415 7.172)"
                  fill="#fff"
                  fillRule="evenodd"
                />
                <path
                  id="Path_458"
                  data-name="Path 458"
                  d="M11.246,15.249A2.249,2.249,0,0,1,13.494,13h.027a2.249,2.249,0,0,1,0,4.5h-.027A2.249,2.249,0,0,1,11.246,15.249Z"
                  transform="translate(18.723 20.941)"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </svg>
              <span>{t('contact')}</span>
            </Link>
            <Link to={'/about-us'} className="box_link_item flex_wrp bg_color1">
              <svg
                id="about"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="50"
                viewBox="0 0 70 60"
              >
                <path
                  id="Path_459"
                  data-name="Path 459"
                  d="M13.5,7.25A2.249,2.249,0,0,1,15.747,9.5V24.49a2.249,2.249,0,1,1-4.5,0V9.5A2.249,2.249,0,0,1,13.5,7.25Z"
                  transform="translate(18.732 10.739)"
                  fill="#fff"
                  fillRule="evenodd"
                />
                <path
                  id="Path_460"
                  data-name="Path 460"
                  d="M20.48,3.994a31.826,31.826,0,0,1,13-2.744A32.231,32.231,0,1,1,7.695,14.144a2.249,2.249,0,0,1,3.6,2.695A27.733,27.733,0,1,0,33.481,5.747,27.329,27.329,0,0,0,22.316,8.1,2.249,2.249,0,0,1,20.48,3.994Z"
                  transform="translate(-1.25 -1.25)"
                  fill="#fff"
                  fillRule="evenodd"
                />
                <path
                  id="Path_461"
                  data-name="Path 461"
                  d="M10.995,18a3,3,0,0,1,3-3h.027a3,3,0,0,1,0,6h-.027A3,3,0,0,1,10.995,18Z"
                  transform="translate(18.222 26.225)"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </svg>
              <span>{t('about')}</span>
            </Link>
            {/* <Link to={'/tickets'} className="box_link_item flex_wrp bg_color1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="50"
                height="50"
                x="0"
                y="0"
                viewBox="0 0 128 128"
              >
                <g>
                  <g>
                    <path
                      d="m101.463 54.16a1.749 1.749 0 0 0 1.75-1.75v-13.4a1.749 1.749 0 0 0 -1.75-1.75h-74.925a1.749 1.749 0 0 0 -1.75 1.75v13.4a1.749 1.749 0 0 0 1.75 1.75 9.84 9.84 0 0 1 0 19.68 1.749 1.749 0 0 0 -1.75 1.75v13.4a1.749 1.749 0 0 0 1.75 1.75h74.925a1.749 1.749 0 0 0 1.75-1.75v-13.4a1.749 1.749 0 0 0 -1.75-1.75 9.84 9.84 0 1 1 0-19.68zm-13.34 9.84a13.361 13.361 0 0 0 11.59 13.226v10.016h-44.713v-3.25a1.75 1.75 0 0 0 -3.5 0v3.25h-23.212v-10.016a13.342 13.342 0 0 0 0-26.452v-10.016h23.212v3.25a1.75 1.75 0 1 0 3.5 0v-3.25h44.713v10.016a13.361 13.361 0 0 0 -11.59 13.226z"
                      fill="#ffffff"
                      data-original="#000000"
                    ></path>
                    <path
                      d="m53.25 66.249a1.75 1.75 0 0 0 -1.75 1.751v8a1.75 1.75 0 0 0 3.5 0v-8a1.75 1.75 0 0 0 -1.75-1.751z"
                      fill="#ffffff"
                      data-original="#000000"
                    ></path>
                    <path
                      d="m53.25 50.255a1.75 1.75 0 0 0 -1.75 1.75v8a1.75 1.75 0 1 0 3.5-.005v-8a1.75 1.75 0 0 0 -1.75-1.745z"
                      fill="#ffffff"
                      data-original="#000000"
                    ></path>
                  </g>
                </g>
              </svg>
              <span>{t('tickets')}</span>
            </Link> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Profile
