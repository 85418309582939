import React, { useState } from 'react'
import { Link, Navigate, createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {Bounce, ToastContainer, toast} from 'react-toastify'
import axios from 'axios'
import {useFormik} from 'formik'
import { useTranslation } from 'react-i18next'
import HeaderPages from '../components/header/HeaderPages'
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import { Footer } from '../components/Footer/Footer'
import verifyImg from '../assets/images/sm-login.png'
import OTPInput from 'react-otp-input'

export const CheckOtp = () => {
  const {t} = useTranslation();

  const [otp, setOtp] = useState('')

  const navigate = useNavigate()

  const lang = localStorage.getItem('site_lang') || 'ar'

  const getAuthHeader = () => ({
    'Accept-Language': lang,
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'content-type': 'multipart/form-data',
  })

  const [searchParams, setSearchParams] = useSearchParams();

  const email = searchParams.get("email");

  console.log(email);

  const CheckOtp = useFormik({
    initialValues: {
      code_1: '',
      code_2: '',
      code_3: '',
      code_4: '',
    },
    onSubmit: async (values) => {
      axios
        .post( 'https://media.thecontentapp.net/dashboard/api/media/auth/password/checkCode',  {  code: otp, email: email },  {headers: getAuthHeader()} )
        .then((response: any) => {
          if (response.status !== false && response.status !== 404) {
          toast.success(response.msg, {
            position: 'top-center',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Bounce,
          })
          setTimeout(() => {
            navigate({
              pathname: "/change-password",
              search: `?${createSearchParams({
                email: email,
              })}`
            })
          }, 500)
        }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.data.msg, {
            position: 'top-center',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Bounce,
          })
        })
        
    },
  })

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container sm:mx-auto mt-20">
        <Breadcrumb title={t('rememberPassword')} />
        <div className="tcon-about-section py-5 lg:py-8 lg:w-11/12 md:w-11/12 m-auto">
          <div className="relative isolate mx-auto xl:container">
            <form onSubmit={CheckOtp.handleSubmit}>
              <div className="space-y-12">
                <div className="rounded-xl border-gray-900/10 pb-12 p-5 lg:p-16 bg-slate-100">
                  <h2 className="text-center text-xl font-semibold leading-7 text-gray-900">
                  {t('rememberPassword')}
                  </h2>

                  <div className="flex flex-wrap items-center justify-between p-6 lg:px-8">

                    <div className="flex-2 w-full lg:w-50 items-center lg:flex-1">

                      <div className="item-center">
                        <div className="my-8 m-auto">
                          <label
                            htmlFor="phone"
                            className="block text-start text-sm font-medium leading-6 text-gray-900 mb-2"
                          >
                         {t('email')}
                          </label>
                          <div className="my-8 flex OTPInput">
                            <OTPInput
                              onChange={setOtp}
                              value={otp}
                              inputStyle="inputStyle"
                              numInputs={4}
                              renderInput={(props) => <input {...props} />}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center col-span-full item-center m-auto justify-between">
                          <button
                          type="submit"
                          className={otp === '' ? "bg-primary_color rounded-md bg-indigo-600 px-24 py-5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-75"
                          : "bg-primary_color rounded-md bg-indigo-600 px-24 py-5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"}
                          >
                          {t('send')}
                          </button>
                      </div>
                    </div>
                    <div className="flex-2 w-100 lg:w-50 items-center lg:flex-1">
                        <img className="pr-20" src={verifyImg} alt='verify' />
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  )
}

export default CheckOtp
