import React from 'react'
import HeroSection from '../../components/HeroSection/HeroSection'
import { OurServices } from '../../components/OurServices/OurServices'
import { Why } from '../../components/Why/Why'
import { Footer } from '../../components/Footer/Footer'
import { AboutUs } from '../../components/AboutUs/AboutUs'
import { Clients } from '../../components/Clients/Index'
import { ToastContainer } from 'react-toastify'
import { Vision } from '../../components/Vision/Vision'
import { Impact } from '../../components/Impact/Impact'
import { Supporters } from '../../components/Supporters/Index'


export const HomePage = () => {
  return (
    <div className="sm-app">
      <HeroSection />
      <AboutUs />
      <Vision />
      <OurServices />
      <Why />
      <Impact className={'tcon-impact-wrapper'} />
      <Supporters />
      <Clients />
      <ToastContainer />
      <Footer />
    </div>
  )
}

export default HomePage