import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'


export interface IBreadcrumbProps {
    title?: string;
    subTitle?: string;
    newOrder?: string;
}

export const Breadcrumb = (props: IBreadcrumbProps) => {
    const {t} = useTranslation();

  return (
    <div className='tcon-breadcrumb-pages-wrapper container px-5'>
        <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                    <Link to={'/'} className="inline-flex items-center text-base font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                        <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                        </svg>
                        {t('home')}
                    </Link>
                </li>
                <li aria-current="page">
                    <div className="flex items-center">
                        <span className='ml-3 mr-3'>/</span>
                        <span className="ml-1 text-base font-medium text-gray-500 md:ml-2 dark:text-gray-400">{props.title}</span>
                    </div>
                </li>
                {props.subTitle ? 
                <li aria-current="page">
                    <div className="flex items-center">
                        <span className='ml-3'>/</span>
                        <span className="ml-1 text-base font-medium text-gray-500 md:ml-2 dark:text-gray-400">{props.subTitle}</span>
                    </div>
                </li>
                : '' }
                {props.newOrder ? 
                <li aria-current="page">
                    <div className="flex items-center">
                        <span className='ml-3'>/</span>
                        <span className="ml-1 text-base font-medium text-gray-500 md:ml-2 dark:text-gray-400">{props.newOrder}</span>
                    </div>
                </li>
                : '' }
                
            </ol>
        </nav>
    </div>
  )
}

export default Breadcrumb