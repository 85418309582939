import React, {Fragment, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {ApiService, makeRequest} from '../../services/data.service'
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import upl from '../../assets/images/upl.svg'

import profile from '../../assets/images/profile-image.png'
import ImgDefault from '../../assets/images/icon/default.jpg'

export const SingleOrder = () => {
  const {t} = useTranslation()
  const order_id = useParams()

  const [serviceData, setServiceData] = useState<any>()
  const [orderData, setOrderData] = useState<any>()
  const [executor, setExecutor] = useState<any>()
  const [providerAttachment, setProviderAttachment] = useState<any>()
  const [open, setOpen] = useState(false)
  const [openReason, setOpenReason] = useState(false)
  const [openDesc, setOpenDesc] = useState(false)
  const [openUncomplate, setOpenUncomplate] = useState(false)
  const [lodding, setLodding] = useState(false)
  const [loddingClose, setLoddingClose] = useState(false)
  const [contentData, setContentData] = useState<any>()

  const [data, setData] = useState<any>([])
  const idUser = JSON.parse(localStorage.getItem('id_user') || '{}')

  const navigate = useNavigate()

  useEffect(() => {
    fetch('/chat.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        setData(data.Chat.Orders)
      })
      .catch((error) => {
        console.error('Error fetching the data:', error)
      })

    // makeRequest
    //   .ApiService('chat')
    //   .then((response: any) => {
    //     setData(response.data?.data?.Orders)
    //   })
    //   .catch((err: any) => {
    //     console.log(err)
    //   })

    ApiService.DetailsOrder({
      order_id: order_id.id,
    })
      .then((response) => {
        setOrderData(response.data)
        setServiceData(response.data.sub_service)
        setProviderAttachment(response.data.provider_data)
        setExecutor(response.data.provider)
      })
      .catch((err) => {
        if (err.status === 400) {
          navigate('/404')
        }
        console.log(err)
      })
  }, [])

  const handleBlur = () => {
    setOpen(true)
  }

  const handleReason = () => {
    setOpenReason(!openReason)
  }

  const handleDesc = () => {
    setOpenDesc(!openDesc)
  }

  const handleUncomplate = () => {
    setOpenUncomplate(true)
  }

  const handleCloseComplaint = () => {
    setLoddingClose(true)
    ApiService.closeComplaint({
      order_id: order_id.id,
    })
      .then((response: any) => {
        setLodding(false)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleClose = () => {
    setOpen(false)
    setOpenUncomplate(false)
  }

  const handleCancel = () => {
    ApiService.cancelOrder({
      order_id: order_id.id,
    })
      .then((response) => {
        setLodding(true)
        setTimeout(() => {
          window.location.reload()
        }, 500)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleComplete = () => {
    ApiService.completeOrder({
      order_id: order_id.id,
    })
      .then((response) => {
        setLodding(true)
        setTimeout(() => {
          window.location.reload()
        }, 500)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const SignupSchema = Yup.object().shape({
    message: Yup.string().min(2, 'Too Short!').required(t('massageerror')),
  })

  const Complaint = useFormik({
    initialValues: {
      check: false,
      message: '',
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      ApiService.userComplaint({
        order_id: order_id.id,
        reason: values.message,
        platform_id: 2,
      })
        .then((response) => {
          setOpen(false)
          setTimeout(() => {
            window.location.reload()
          }, 500)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  })

  const UnComplete = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      ApiService.unComplete({
        order_id: order_id.id,
        reason: values.message,
      })
        .then((response) => {
          setLodding(true)
          setOpenUncomplate(false)
          setTimeout(() => {
            window.location.reload()
          }, 500)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  })

  const getFileNameFromUrl = (url) => {
    const parts = url.split('/')
    return parts[parts.length - 1]
  }

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb title={t('myorders')} />
        <div className="tcon-about-section">
          <div className="tcon-about-section m-auto w-full py-10 lg:py-20">
            <div className="relative isolate mx-auto xl:container">
              <React.Fragment>
                <div className="space-y-12">
                  <div className="box_border pd_20">
                    <div className="social_contact_cnt flex items-center rounded-md border px-5 py-5">
                      <div className="soc_icon">
                        <span className="text-gray-500">
                          <img
                            src={serviceData?.mediaImage}
                            alt={serviceData?.name}
                          />
                        </span>
                      </div>
                      <div className="txt_cnt">
                        <h2>{serviceData?.name}</h2>
                        <p>{serviceData?.main_service_name}</p>
                        <span>{serviceData?.description}</span>
                      </div>
                    </div>
                  </div>

                  <div className="mb-4 flex flex-col lg:flex-row">
                    <div className="w-full md:w-full lg:w-2/3">
                      <div className="col-span-2 p-5">
                        <div className="col-span-2 row-span-2 mb-5 rounded-md border p-5">
                          <h2 className="mb-5 text-start text-xl font-semibold leading-7 text-gray-900">
                            {t('orderdetails')}
                          </h2>
                          <p>{orderData?.order_description}</p>
                        </div>
                        {orderData?.is_media === true &&
                          orderData?.media_text && (
                            <div
                              className="mb-4 rounded-lg bg-blue-50 p-4 text-sm text-blue-800"
                              role="alert"
                            >
                              {orderData?.media_text}
                            </div>
                          )}
                        {orderData?.status_id.id === 2 ? (
                          <div
                            className="mb-4 rounded-lg bg-blue-50 p-4 text-sm text-blue-800"
                            role="alert"
                          >
                            {t('pleasewaitrequest')}
                          </div>
                        ) : (
                          ''
                        )}
                        {orderData?.status_id.id === 4 ||
                        orderData?.status_id.id === 5 ||
                        orderData?.status_id.id === 6 ||
                        orderData?.status_id.id === 7 ||
                        orderData?.status_id.id === 8 ? (
                          <div className="col-span-2 row-span-2 rounded-md border p-5">
                            <h2 className="mb-5 text-start text-xl font-semibold leading-7 text-gray-900">
                              {t('serviceprovider')}
                            </h2>
                            <div className="service_port_box pd_20">
                              <div className="service_port_cn flex_wrp">
                                <div className="owner_how d-flex">
                                  <div className="pic_owner bg-white">
                                    <span>
                                      {executor?.image ? (
                                        <img
                                          src={executor?.image}
                                          alt={executor?.name}
                                        />
                                      ) : (
                                        <img
                                          src={profile}
                                          alt={executor?.name}
                                        />
                                      )}
                                    </span>
                                  </div>
                                  <div className="txt_owner">
                                    {executor?.show_name ? (
                                      <h3>{executor?.name}</h3>
                                    ) : (
                                      t('creative')
                                    )}
                                    {Array.isArray(data) &&
                                      data.map(
                                        (list: any, index: number) =>
                                          list.orderId === order_id.id && (
                                            <Link
                                              key={index}
                                              className="text-dark me-3 mt-3 w-full rounded-md border px-3.5 py-2.5 text-center text-sm font-semibold"
                                              to={`/chat/${order_id.id}`}
                                              onClick={() => {
                                                localStorage.setItem(
                                                  'order_id',
                                                  serviceData.id
                                                )
                                                localStorage.setItem(
                                                  'id_executor',
                                                  executor?.id
                                                )
                                              }}
                                            >
                                              {t('contactwith')}
                                            </Link>
                                          )
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {providerAttachment &&
                              providerAttachment.length !== 0 && (
                                <div className="col-span-2 row-span-2 mb-5 rounded-md border p-5">
                                  <h2 className="mb-5 text-start text-xl font-semibold leading-7 text-gray-900">
                                    {t('attachfromprovider')}
                                  </h2>
                                  {providerAttachment &&
                                    providerAttachment.map(
                                      (item: any, index: number) => (
                                        <div
                                          className="mb-4 border-b-2 pb-4"
                                          key={index}
                                        >
                                          <div className="mb-6 flex items-center justify-between">
                                            <p className="">{item.date_time}</p>
                                            <div className="flex items-end justify-end gap-2">
                                              {item.reason && (
                                                <>
                                                  <p
                                                    className="w-full min-w-max cursor-pointer text-end underline"
                                                    onClick={handleReason}
                                                  >
                                                    {t('reason')}
                                                  </p>
                                                  {openReason === true && (
                                                    <Fragment>
                                                      <div className="fixed inset-0 z-50 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                                                      <div className="main-modal h-100 animated fadeIn faster fixed inset-0 z-50 flex w-full items-center justify-center overflow-hidden">
                                                        <div className="modal-container z-50 mx-auto w-11/12 overflow-y-auto rounded bg-white shadow-lg md:max-w-md">
                                                          <div className="modal-content px-6 py-4 text-left">
                                                            <div className="flex items-center justify-between pb-3">
                                                              <p className="text-2xl font-bold">
                                                                {t('reason')}
                                                              </p>
                                                              <div
                                                                onClick={
                                                                  handleReason
                                                                }
                                                                className="modal-close z-50 cursor-pointer"
                                                              >
                                                                <svg
                                                                  className="fill-current text-black"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="18"
                                                                  height="18"
                                                                  viewBox="0 0 18 18"
                                                                >
                                                                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                                                                </svg>
                                                              </div>
                                                            </div>

                                                            <p className="py-6 text-center text-lg">
                                                              {item.reason}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Fragment>
                                                  )}
                                                </>
                                              )}
                                              {item.status.id === 8 && (
                                                <p className="w-full rounded border border-red-100 bg-red-100 px-1.5 py-0.5 text-sm font-semibold text-red-800 dark:border-red-400 dark:bg-gray-700 dark:text-red-400">
                                                  {item.status.name}
                                                </p>
                                              )}
                                              {item.status.id === 5 && (
                                                <p className="w-full rounded border border-green-100 bg-green-100 px-1.5 py-0.5 text-sm font-semibold text-green-800 dark:border-green-400 dark:bg-gray-700 dark:text-green-400">
                                                  {item.status.name}
                                                </p>
                                              )}
                                              {item.status.id === 6 && (
                                                <p className="w-full rounded border border-blue-100 bg-blue-100 px-1.5 py-0.5 text-sm font-semibold text-blue-800 dark:border-blue-400 dark:bg-gray-700 dark:text-blue-400">
                                                  {item.status.name}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="grid max-w-xl grid-cols-1 items-center gap-x-8 gap-y-10 md:max-w-none md:grid-cols-2 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
                                            {item.files.map(
                                              (file: any, index: number) => (
                                                <div
                                                  className="file-container"
                                                  key={index}
                                                >
                                                  <div className="file_itm flex items-center">
                                                    {file?.description ? (
                                                      <div className="fle_txt">
                                                        <p>{file.fileName}</p>
                                                        <span>{file.file}</span>
                                                      </div>
                                                    ) : file?.type === 'url' ? (
                                                      <Fragment>
                                                        <div className="fle_icon me-3">
                                                          <span>
                                                            <img
                                                              src={upl}
                                                              alt={
                                                                file.fileName
                                                              }
                                                            />
                                                          </span>
                                                        </div>
                                                        <Link
                                                          to={file.attachment}
                                                          target="_blank"
                                                        >
                                                          <div className="fle_txt">
                                                            <p className="font-bold">
                                                              {getFileNameFromUrl(
                                                                file.attachment
                                                              )}
                                                            </p>
                                                            <p>
                                                              {file.fileName}
                                                            </p>
                                                          </div>
                                                        </Link>
                                                      </Fragment>
                                                    ) : (
                                                      <Fragment>
                                                        <div className="fle_icon me-3">
                                                          <span>
                                                            <img
                                                              src={upl}
                                                              alt={
                                                                file.fileName
                                                              }
                                                            />
                                                          </span>
                                                        </div>
                                                        <Link
                                                          to={file.attachment}
                                                          target="_blank"
                                                        >
                                                          <div className="fle_txt">
                                                            <p className="font-bold">
                                                              {getFileNameFromUrl(
                                                                file.attachment
                                                              )}
                                                            </p>
                                                            <p>
                                                              {file.fileName}
                                                            </p>
                                                          </div>
                                                        </Link>
                                                      </Fragment>
                                                    )}
                                                  </div>
                                                </div>
                                              )
                                            )}
                                            {item.url ? (
                                              <div className="fle_txt flex items-center">
                                                <img
                                                  src={upl}
                                                  width={'40px'}
                                                  height={'40px'}
                                                  alt={item.description}
                                                />
                                                <div className=" ms-2">
                                                  <p className="font-bold">
                                                    {t('link')}
                                                  </p>
                                                  <Link
                                                    target="_blank"
                                                    to={item.url}
                                                  >
                                                    {item.url}
                                                  </Link>
                                                </div>
                                              </div>
                                            ) : null}
                                            {item.description ? (
                                              <div className="fle_txt flex items-center">
                                                <img
                                                  src={upl}
                                                  width={'40px'}
                                                  height={'40px'}
                                                  alt={item.description}
                                                />
                                                <div className=" ms-2">
                                                  <p className="font-bold">
                                                    {t('description')}
                                                  </p>
                                                  <p
                                                    className="cursor-pointer"
                                                    onClick={handleDesc}
                                                  >
                                                    {t('clicktoread')}
                                                  </p>
                                                  {openDesc === true && (
                                                    <Fragment>
                                                      <div className="fixed inset-0 z-50 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                                                      <div className="main-modal h-100 animated fadeIn faster fixed inset-0 z-50 flex w-full items-center justify-center overflow-hidden">
                                                        <div className="modal-container z-50 mx-auto w-11/12 overflow-y-auto rounded bg-white shadow-lg md:max-w-md">
                                                          <div className="modal-content px-6 py-4 text-left">
                                                            <div className="flex items-center justify-between pb-3">
                                                              <p className="text-2xl font-bold">
                                                                {t(
                                                                  'description'
                                                                )}
                                                              </p>
                                                              <div
                                                                onClick={
                                                                  handleDesc
                                                                }
                                                                className="modal-close z-50 cursor-pointer"
                                                              >
                                                                <svg
                                                                  className="fill-current text-black"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="18"
                                                                  height="18"
                                                                  viewBox="0 0 18 18"
                                                                >
                                                                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                                                                </svg>
                                                              </div>
                                                            </div>

                                                            <p className="py-6 text-center text-lg">
                                                              {item.description}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Fragment>
                                                  )}
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>
                              )}
                            <div className="mt-4 flex">
                              {orderData?.status_id.id === 5 &&
                                providerAttachment &&
                                providerAttachment.length !== 0 &&
                                serviceData.is_complaining !== true && (
                                  <Fragment>
                                    <div className="me-3 w-1/3">
                                      <button
                                        onClick={handleComplete}
                                        className="text-md inline-block w-full rounded-md bg-green-600 px-3.5 py-2.5 text-center font-semibold text-white"
                                      >
                                        {t('thankscomplate')}
                                        {lodding && (
                                          <svg
                                            aria-hidden="true"
                                            role="status"
                                            className="mr-2 inline h-4 w-4 animate-spin text-gray-200 dark:text-gray-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                              fill="currentColor"
                                            ></path>
                                            <path
                                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                              fill="#1C64F2"
                                            ></path>
                                          </svg>
                                        )}
                                      </button>
                                    </div>
                                    <div className="me-3 w-1/3">
                                      <button
                                        onClick={handleUncomplate}
                                        className="text-md inline-block w-full rounded-md border-2 bg-transparent px-3.5 py-2.5 text-center font-semibold text-black"
                                      >
                                        {t('ordernotcomplate')}
                                        {lodding && (
                                          <svg
                                            aria-hidden="true"
                                            role="status"
                                            className="mr-2 inline h-4 w-4 animate-spin text-gray-200 dark:text-gray-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                              fill="currentColor"
                                            ></path>
                                            <path
                                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                              fill="#1C64F2"
                                            ></path>
                                          </svg>
                                        )}
                                      </button>
                                    </div>
                                  </Fragment>
                                )}
                              <div className="w-1/3">
                                {orderData?.status_id.id !== 5 &&
                                orderData?.status_id.id !== 6 ? (
                                  orderData?.status_id.id === 7 ? (
                                    <button
                                      className="text-md me-3 w-full rounded-md bg-orange-600 px-3.5 py-2.5 text-center font-semibold text-white"
                                      onClick={handleCloseComplaint}
                                    >
                                      {t('cancelcomplaint')}
                                      {loddingClose && (
                                        <svg
                                          aria-hidden="true"
                                          role="status"
                                          className="mr-2 inline h-4 w-4 animate-spin text-gray-200 dark:text-gray-600"
                                          viewBox="0 0 100 101"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                          ></path>
                                          <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="#1C64F2"
                                          ></path>
                                        </svg>
                                      )}
                                    </button>
                                  ) : (
                                    <button
                                      className="text-md me-3 w-full rounded-md bg-red-600 px-3.5 py-2.5 text-center font-semibold text-white"
                                      onClick={handleBlur}
                                    >
                                      {lodding && (
                                        <svg
                                          aria-hidden="true"
                                          role="status"
                                          className="mr-2 inline h-4 w-4 animate-spin text-gray-200 dark:text-gray-600"
                                          viewBox="0 0 100 101"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                          ></path>
                                          <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="#1C64F2"
                                          ></path>
                                        </svg>
                                      )}
                                      {t('makecomplaint')}
                                    </button>
                                  )
                                ) : null}

                                {open === true && (
                                  <Fragment>
                                    <div className="fixed inset-0 z-50 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                                    <div className="main-modal h-100 animated fadeIn faster fixed inset-0 z-50 flex w-full items-center justify-center overflow-hidden">
                                      <div className="modal-container z-50 mx-auto w-11/12 overflow-y-auto rounded bg-white shadow-lg md:max-w-md">
                                        <div className="modal-content px-6 py-4 text-left">
                                          <div className="flex items-center justify-between pb-3">
                                            <p className="text-2xl font-bold">
                                              {t('makecomplaint')}
                                            </p>
                                            <div
                                              onClick={handleClose}
                                              className="modal-close z-50 cursor-pointer"
                                            >
                                              <svg
                                                className="fill-current text-black"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                              >
                                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                                              </svg>
                                            </div>
                                          </div>
                                          <form
                                            onSubmit={Complaint.handleSubmit}
                                          >
                                            <div className="my-5">
                                              <div className="form-check my-5">
                                                <input
                                                  className="form-check-input flexCheckDefault parent_service"
                                                  type="checkbox"
                                                  value={serviceData?.id}
                                                  checked
                                                  id="flexCheckDefault"
                                                  name="check"
                                                  onChange={() =>
                                                    Complaint.setFieldValue(
                                                      'check',
                                                      !Complaint.values.check
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="flexCheckDefault"
                                                >
                                                  {serviceData?.name} -
                                                  {
                                                    serviceData?.main_service_name
                                                  }
                                                </label>
                                              </div>
                                              <div className="col-span-full text-start">
                                                <label
                                                  htmlFor="message"
                                                  className="block text-sm font-medium leading-6 text-gray-900"
                                                >
                                                  {t('complaintdetails')}
                                                </label>
                                                <div className="mt-2">
                                                  <textarea
                                                    id="message"
                                                    name="message"
                                                    onChange={
                                                      Complaint.handleChange
                                                    }
                                                    onBlur={
                                                      Complaint.handleBlur
                                                    }
                                                    value={
                                                      Complaint.values.message
                                                    }
                                                    placeholder={t(
                                                      'complaintdetails'
                                                    )}
                                                    className="block h-40 w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                  ></textarea>
                                                  {Complaint.errors.message &&
                                                    Complaint.touched
                                                      .message && (
                                                      <div className="mt-2 text-sm text-red-600">
                                                        {
                                                          Complaint.errors
                                                            .message
                                                        }
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="flex justify-end pt-2">
                                              <button
                                                onClick={handleClose}
                                                className="modal-close rounded-lg bg-gray-400 p-3 px-4 text-white hover:bg-gray-300 focus:outline-none"
                                              >
                                                {t('close')}
                                              </button>
                                              <button className="ms-3 rounded-lg bg-teal-500 p-3 px-4 text-white hover:bg-teal-400 focus:outline-none">
                                                {t('save')}
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                )}
                                {openUncomplate === true && (
                                  <Fragment>
                                    <div className="fixed inset-0 z-50 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                                    <div className="main-modal h-100 animated fadeIn faster fixed inset-0 z-50 flex w-full items-center justify-center overflow-hidden">
                                      <div className="modal-container z-50 mx-auto w-11/12 overflow-y-auto rounded bg-white shadow-lg shadow-lg md:max-w-md">
                                        <div className="modal-content px-6 py-4 text-left">
                                          <div className="flex items-center justify-between pb-3">
                                            <p className="text-2xl font-bold">
                                              {t('ordernotcomplate')}
                                            </p>
                                            <div
                                              onClick={handleClose}
                                              className="modal-close z-50 cursor-pointer"
                                            >
                                              <svg
                                                className="fill-current text-black"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                              >
                                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                                              </svg>
                                            </div>
                                          </div>
                                          <form
                                            onSubmit={UnComplete.handleSubmit}
                                          >
                                            <div className="my-5">
                                              <div className="col-span-full text-start">
                                                <label
                                                  htmlFor="message"
                                                  className="block text-sm font-medium leading-6 text-gray-900"
                                                >
                                                  {t('editdetails')}
                                                </label>
                                                <div className="mt-2">
                                                  <textarea
                                                    id="message"
                                                    name="message"
                                                    onChange={
                                                      UnComplete.handleChange
                                                    }
                                                    onBlur={
                                                      UnComplete.handleBlur
                                                    }
                                                    value={
                                                      UnComplete.values.message
                                                    }
                                                    placeholder={t(
                                                      'editdetails'
                                                    )}
                                                    className="block h-40 w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                  ></textarea>
                                                  {UnComplete.errors.message &&
                                                    UnComplete.touched
                                                      .message && (
                                                      <div className="mt-2 text-sm text-red-600">
                                                        {
                                                          UnComplete.errors
                                                            .message
                                                        }
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="flex justify-end pt-2">
                                              <button
                                                onClick={handleClose}
                                                className="modal-close rounded-lg bg-gray-400 p-3 px-4 text-white hover:bg-gray-300 focus:outline-none"
                                              >
                                                {t('close')}
                                              </button>
                                              <button className="ms-3 rounded-lg bg-teal-500 p-3 px-4 text-white hover:bg-teal-400 focus:outline-none">
                                                {t('save')}
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="w-full px-6 md:w-full lg:w-1/3">
                      <div className="row-span-3">
                        {orderData && orderData.is_media !== true ? (
                          <div className="col-span-2 row-span-2 mb-5 p-5">
                            <div className="box_wizerd_step">
                              <div className="step_cn clearfix">
                                <div
                                  className={
                                    orderData?.status_id.id === 2
                                      ? 'step_item active'
                                      : 'step_item active'
                                  }
                                >
                                  <div className="bx_num">
                                    <span className="bx_num_txt">01</span>
                                  </div>
                                  <p>{t('receiveorders')}</p>
                                </div>
                                <div
                                  className={
                                    orderData?.status_id.id === 4 ||
                                    orderData?.status_id.id === 5 ||
                                    orderData?.status_id.id === 6 ||
                                    orderData?.status_id.id === 7
                                      ? 'step_item active'
                                      : 'step_item'
                                  }
                                >
                                  <div className="bx_num">
                                    <span className="bx_num_txt">02</span>
                                  </div>
                                  <p>{t('implementation')}</p>
                                </div>
                                <div
                                  className={
                                    orderData?.status_id.id === 5 ||
                                    orderData?.status_id.id === 6
                                      ? 'step_item active'
                                      : 'step_item'
                                  }
                                >
                                  <div className="bx_num">
                                    <span className="bx_num_txt">03</span>
                                  </div>
                                  <p>{t('delivery')}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        <div className="col-span-2 row-span-2 rounded-md border p-5">
                          <div className="group_card_project">
                            <div className="rw_proCrd flex_wrp">
                              <div className="label_proCrd">
                                <h3>{t('ordernumber')}</h3>
                              </div>
                              <p>#{orderData?.id}</p>
                            </div>
                            <div className="rw_proCrd flex_wrp">
                              <div className="label_proCrd">
                                <h3>{t('orderdate')}</h3>
                              </div>
                              <p>
                                {orderData?.media_date} /{orderData?.time}
                              </p>
                            </div>
                            {serviceData?.is_media !== true && (
                              <div className="rw_proCrd flex_wrp">
                                <div className="label_proCrd">
                                  <h3>{t('orderstutas')}</h3>
                                </div>
                                <div
                                  className={
                                    orderData?.status_id.id === 3
                                      ? 'state_order order-blue'
                                      : orderData?.status_id.id === 2
                                      ? 'state_order order-gray'
                                      : orderData?.status_id.id === 4
                                      ? 'state_order order-blue'
                                      : orderData?.status_id.id === 7 ||
                                        orderData?.status_id.id === 8 ||
                                        orderData?.status_id.id === 9
                                      ? 'state_order order-red'
                                      : 'state_order'
                                  }
                                >
                                  {orderData?.status_id.name}
                                </div>
                              </div>
                            )}

                            <div className="rw_proCrd flex_wrp">
                              <div className="label_proCrd">
                                <h3>{t('orderattach')}</h3>
                              </div>
                              {orderData?.file && (
                                <Link to={orderData.file} target="_blank">
                                  {orderData.file_name}
                                </Link>
                              )}
                            </div>
                            {orderData?.identity_link && (
                              <div className="rw_proCrd flex_wrp">
                                <div className="label_proCrd">
                                  <h3>{t('identity_link')}</h3>
                                </div>
                                <Link
                                  to={orderData.identity_link}
                                  className="text-dark me-3 w-full rounded-md border px-3.5 py-2.5 text-center text-sm font-semibold"
                                  target="_blank"
                                >
                                  {t('identityLink')}
                                </Link>
                              </div>
                            )}
                            <div className="rw_proCrd flex_wrp">
                              {orderData?.voice ? (
                                <Fragment>
                                  <div className="label_proCrd">
                                    <h3>الصوت</h3>
                                  </div>
                                  <audio controls>
                                    <source
                                      src={orderData?.voice}
                                      type="audio/webm"
                                    />
                                    <source
                                      src={orderData?.voice}
                                      type="audio/aac"
                                    ></source>
                                  </audio>
                                </Fragment>
                              ) : (
                                ''
                              )}
                            </div>
                            {orderData && orderData.is_media !== true ? (
                              <Fragment>
                                {orderData?.quantity && (
                                  <div className="rw_proCrd flex_wrp">
                                    <div className="label_proCrd">
                                      <h3>{t('quantity')}</h3>
                                    </div>
                                    <p>{orderData?.quantity}</p>
                                  </div>
                                )}
                                {orderData?.duration !== 0 && (
                                  <div className="rw_proCrd flex_wrp">
                                    <div className="label_proCrd">
                                      <h3>{t('duration')}</h3>
                                    </div>
                                    <p>{orderData?.duration}</p>
                                  </div>
                                )}
                                {orderData?.num_of_words !== 0 && (
                                  <div className="rw_proCrd flex_wrp">
                                    <div className="label_proCrd">
                                      <h3>{t('num_of_words')}</h3>
                                    </div>
                                    <p>{orderData?.num_of_words}</p>
                                  </div>
                                )}
                                {orderData?.translated_language !== null && (
                                  <div className="rw_proCrd flex_wrp">
                                    <div className="label_proCrd">
                                      <h3>{t('languages')}</h3>
                                    </div>
                                    <p>
                                      {orderData?.translated_language?.name}
                                    </p>
                                  </div>
                                )}
                                {/* <div className="rw_proCrd flex_wrp">
                                  <div className="label_proCrd">
                                    <h3>{t('paymentmethod')}</h3>
                                  </div>
                                  <p>{orderData?.payment_method.name}</p>
                                </div>
                                <div className="rw_proCrd flex_wrp">
                                  <div className="label_proCrd">
                                    <h3>{t('servicecost')}</h3>
                                  </div>
                                  <p>
                                    {orderData?.service_price} {t('rs')}
                                  </p>
                                </div>

                                <div className="rw_proCrd flex_wrp">
                                  <div className="label_proCrd">
                                    <h3>{t('totalamount')}</h3>
                                  </div>
                                  <p>
                                    {orderData?.total_price} {t('rs')}
                                  </p>
                                </div> */}
                                <div className="rw_proCrd flex_wrp d-flex flex-column">
                                  {/* <div className="label_proCrd w-full">
                                    <h3 className="w-full text-red-500">
                                      {t('backtoorders')}
                                    </h3>
                                  </div> */}
                                  {/* <p className="w-full text-red-500">
                                    {t('vatservicesnew')} {orderData?.tax_value}{' '}
                                    {t('rs')}
                                  </p> */}
                                </div>
                                {/* <div className="rw_proCrd flex_wrp">
                                  <Link
                                    to={orderData?.invoice}
                                    className="text-dark me-3 w-full rounded-md border px-3.5 py-2.5 text-center text-sm font-semibold"
                                    target="_blank"
                                  >
                                    {t('Downloadinvoice')}
                                  </Link>
                                </div> */}
                                {orderData?.status_id.id === 2 ? (
                                  <div className="mt-4 flex">
                                    <div className="w-2/2">
                                      <Link
                                        to={'/my_orders'}
                                        className="me-3 w-full rounded-sm bg-lime-700 px-3.5 py-2.5 text-center text-sm font-semibold text-white"
                                      >
                                        {t('backtoorders')}
                                      </Link>
                                    </div>
                                    <div className="w-2/4">
                                      <Link
                                        to={''}
                                        className="me-3 w-full rounded-sm bg-red-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white"
                                        onClick={handleCancel}
                                      >
                                        {t('cancellingorder')}
                                      </Link>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </Fragment>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default SingleOrder
