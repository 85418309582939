import React, {Fragment, useEffect, useState} from 'react'
import {makeRequest} from '../../services/data.service'
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import {useTranslation} from 'react-i18next'
import './main.css'
import closeBtn from '../../assets/images/icon/close.svg'
import img6 from '../../assets/images/organizations/صندوق-دعم-الجمعيات.jpg'
import Image from '../../assets/images/Image.png'
import {Link} from 'react-router-dom'

export const CommonOrganizations = () => {
  const {t} = useTranslation()
  const [contentData, setContentData] = useState<any>()
  const [supportersData, setSupportersData] = useState<any>()
  const [contentDes, setContentDes] = useState<any>()
  const [supportersDes, setSupportersDes] = useState<any>()
  const [show, setShow] = useState<any>(false)
  const [showPop, setShowPop] = useState<any>(false)

  useEffect(() => {
    makeRequest.ApiService('media/organizations').then((response: any) => {
      setContentData(response.data)
    })
    makeRequest.ApiService('media/supporters').then((response: any) => {
      setSupportersData(response.data)
    })
  }, [])

  const handelClickdocs = (id: number) => {
    makeRequest.ApiService(`media/organization/${id}`).then((response: any) => {
      setContentDes(response.data)
    })
    setShow(!show)
  }

  const handelClicksupporters = (id: number) => {
    makeRequest.ApiService(`media/supporters/${id}`).then((response: any) => {
      setSupportersDes(response.data)
      console.log(response.data, 'response.data')
    })
    setShowPop(!show)
  }

  const toggleModal = () => {
    setContentDes(null)
    setShow(!show)
  }

  const toggleModalPop = () => {
    setShowPop(!show)
    console.log('click')
  }
  const toggleModalClose = () => {
    setShowPop(false)
    console.log('close')
  }

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb title={t('commonOrganizations')} />
        <div className="tcon-about-section py-20">
          <div className="relative isolate mx-auto xl:container">
            <div className="bg-white py-10">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                  <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {t('supporters')}
                  </h2>
                </div>
                <div className="mx-auto my-16 lg:mt-24">
                  <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 md:max-w-none md:grid-cols-2 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
                    {supportersData &&
                      supportersData
                        .sort((a, b) => a.id - b.id)
                        .map((item: any, index: number) => (
                          <div
                            id={item.id}
                            className="relative rounded-lg p-5 shadow-lg"
                            onClick={() => handelClicksupporters(item.id)}
                            key={index}
                          >
                            <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                              <img
                                src={item.image ? item.image : Image}
                                className="m-auto mb-5 h-24"
                                alt={item.title}
                              />
                              {item.title}
                            </dt>
                          </div>
                        ))}
                  </dl>
                  {showPop && (
                    <div className="modal-overlay z-10">
                      <div className="modal">
                        <button onClick={toggleModalClose} className="close-button">
                          <img className="h-4 w-4" src={closeBtn} alt="close" />
                        </button>
                        {supportersDes && (
                          <div className="py-5">
                            <header className="text-center">
                              {supportersDes.image && (
                                <img
                                  className=" m-auto h-32"
                                  src={supportersDes.image}
                                  alt={supportersDes.title}
                                />
                              )}
                              {supportersDes.title && (
                                <h2 className="my-6 text-center font-semibold">
                                  {supportersDes.title}
                                </h2>
                              )}
                            </header>

                            <div
                              className="mb-6"
                              dangerouslySetInnerHTML={{
                                __html: supportersDes.description,
                              }}
                            ></div>
                            <ul>
                              {supportersDes.address && (
                                <li>
                                  <b>مقرها: </b>
                                  {supportersDes.address}
                                </li>
                              )}
                              {supportersDes.commissioner_name && (
                                <li>
                                  <b>اسم المفوض: </b>
                                  {supportersDes.commissioner_name}
                                </li>
                              )}
                              {supportersDes.commissioner_attribute && (
                                <li>
                                  <b>صفة المفوض: </b>
                                  {supportersDes.commissioner_attribute}
                                </li>
                              )}
                              {supportersDes.phone && (
                                <li>
                                  <b>الجوال: </b>
                                  {supportersDes.phone}
                                </li>
                              )}
                              {supportersDes.email && (
                                <li>
                                  <b>الإيميل: </b>
                                  {supportersDes.email}
                                </li>
                              )}
                              {supportersDes.website && (
                                <li>
                                  <b>الموقع الإلكتروني: </b>
                                  <Link
                                    to={supportersDes.website}
                                    target="_blank"
                                  >
                                    {supportersDes.website}
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="mx-auto max-w-2xl lg:text-center">
                  <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {t('commonOrganizationsWith')}
                  </h2>
                </div>
                <div className="mx-auto my-16 lg:mt-24">
                  <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 md:max-w-none md:grid-cols-2 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
                    {contentData &&
                      contentData
                        .sort((a, b) => a.id - b.id)
                        .map(
                          (item: any, index: number) =>
                            item.id !== 1813 && (
                              <div
                                id={item.id}
                                className="relative rounded-lg p-5 shadow-lg"
                                onClick={() => handelClickdocs(item.id)}
                                key={index}
                              >
                                <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                                  <img
                                    src={item.image ? item.image : Image}
                                    className="m-auto mb-5 h-24"
                                    alt={item.name}
                                  />
                                  {item.name}
                                </dt>
                              </div>
                            )
                        )}
                  </dl>
                  {show && (
                    <div className="modal-overlay z-10">
                      <div className="modal">
                        <button onClick={toggleModal} className="close-button">
                          <img className="h-4 w-4" src={closeBtn} alt="close" />
                        </button>
                        {contentDes && (
                          <div className="py-5">
                            <header className="text-center">
                              {contentDes.image && (
                                <img
                                  className=" m-auto h-32"
                                  src={contentDes.image}
                                  alt={contentDes.name}
                                />
                              )}
                              {contentDes.name && (
                                <h2 className="my-6 text-center font-semibold">
                                  {contentDes.name}
                                </h2>
                              )}
                            </header>

                            <div
                              className="mb-6"
                              dangerouslySetInnerHTML={{
                                __html: contentDes.description,
                              }}
                            ></div>
                            <ul>
                              {contentDes.location && (
                                <li>
                                  <b>مقرها: </b>
                                  {contentDes.location}
                                </li>
                              )}
                              {contentDes.commissioner_name && (
                                <li>
                                  <b>اسم المفوض: </b>
                                  {contentDes.commissioner_name}
                                </li>
                              )}
                              {contentDes.commissioner_attribute && (
                                <li>
                                  <b>صفة المفوض: </b>
                                  {contentDes.commissioner_attribute}
                                </li>
                              )}
                              {contentDes.phone && (
                                <li>
                                  <b>الجوال: </b>
                                  {contentDes.phone}
                                </li>
                              )}
                              {contentDes.email && (
                                <li>
                                  <b>الإيميل: </b>
                                  {contentDes.email}
                                </li>
                              )}
                              {contentDes.website && (
                                <li>
                                  <b>الموقع الإلكتروني: </b>
                                  <Link to={contentDes.website} target="_blank">
                                    {contentDes.website}
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CommonOrganizations
