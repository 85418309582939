import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { ApiService } from '../../services/data.service';
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import { Footer } from '../../components/Footer/Footer';

export const PrivacyPolicy = () => {
  
  const {t} = useTranslation()
  const [contentData, setContentData] = useState<any>()

  useEffect(() => {
    ApiService.getTerms()
    .then((response: any) => {
        setContentData(response.data.privacy_policy)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb title={t('privacypolicy')} />
        <div className="tcon-about-section py-20">
            <div className="relative xl:container isolate mx-auto">
                <div className="flex flex-wrap items-start justify-between p-6 lg:px-8">
                    <div className="flex-1 w-100 lg:w-50 mt-10 lg:mt-0 md:mr-5">
                        <div className="text-start">
                          <h1 className="text-3xl font-bold tracking-tight sm:text-6xl mb-5">{t('privacypolicy')}</h1>
                          <div className="" dangerouslySetInnerHTML={{ __html: contentData?.description }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
