import React, {useEffect, useState} from 'react'
import {makeRequest} from '../../services/data.service'
import {useTranslation} from 'react-i18next'

export interface IProps {
  className?: string
}

export type ISettings = {
  logo?: string
  website_name?: string
  primary_color: string
  secondary_color: string
  activate_color: string
}

export const Impact = (props: IProps) => {
  const {t} = useTranslation()

  const [impactData, setImpactData] = useState<any>()

  useEffect(() => {
    makeRequest
    .ApiService('media/home')
    .then((response: any) => {
      setImpactData(response.data?.impact)
    })
    .catch((err: any) => {
      console.log(err)
    })
  }, [])

  return (
    <React.Fragment>
      <div className={`${props.className} py-15 sm:py-32`}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {t('impact')}
            </h2>
          </div>
          <div className="mx-auto my-16 lg:mt-16">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 md:max-w-none md:grid-cols-2 lg:max-w-none lg:grid-cols-5 lg:gap-y-16">
              {impactData &&
                impactData.map((item: any, index: number) => (
                  <div
                    className="relative rounded-lg p-5 text-center"
                    key={index}
                  >
                    <dt className="text-center text-md font-semibold leading-7 text-white">
                      {item.title}
                    </dt>
                  </div>
                ))}
            </dl>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
