import React, {useEffect, useState} from 'react'
import {ApiService, makeRequest} from '../../services/data.service'
import { useTranslation } from 'react-i18next'

export type ISettings = {
  logo?: string
  website_name?: string
  primary_color: string
  secondary_color: string
  activate_color: string
}

export const Why = () => {
  const [whyData, setWhyData] = useState([])
  const [settingsData, setSettingsData] = useState<ISettings>()

  const {t} = useTranslation();

  useEffect(() => {
    makeRequest
      .ApiService('media/home')
      .then((response: any) => {
        setWhyData(response.data?.whyUs)
      })
      .catch((err: any) => {
        console.log(err)
      })
    ApiService.Settings()
      .then((response) => {
        setSettingsData(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <React.Fragment>
      <div
        id="whysm"
        className={`tcon-why-us-wrapper py-15 sm:py-32 bg-[${
          settingsData && settingsData.secondary_color
        }]`}
      >
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {t('whyUs')}
            </h2>
          </div>
          <div className="mx-auto my-16 lg:mt-16">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 md:max-w-none md:grid-cols-2 lg:max-w-none lg:grid-cols-4 lg:gap-y-16">
              {whyData &&
                whyData.map((item: any, index: number) => (
                  <div
                    className="relative rounded-lg p-5 text-center"
                    key={index}
                  >
                    <dt className="text-center text-xl font-semibold leading-7 text-white">
                      <div className="pm-3 mx-auto mb-5 flex h-20 w-20 items-center justify-center rounded-lg">
                        <img
                          className="h-full"
                          src={item.image}
                          alt={item.title}
                        />
                      </div>
                      {item.title}
                    </dt>
                  </div>
                ))}
            </dl>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
