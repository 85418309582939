import React, {Fragment, useEffect, useRef, useState} from 'react'
import moment from 'moment'
import {useFormik} from 'formik'
import {Link, useParams} from 'react-router-dom'
import {ref, onValue, push} from 'firebase/database'
import {ApiService} from '../../services/data.service'
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import {db} from '../../services/firebase'
import FileUploadChat from './FileUploadChat'
import {useTranslation} from 'react-i18next'
import ImgDefault from '../../assets/images/icon/default.jpg'

export const Chat = () => {
  const {t} = useTranslation()
  const order_id = localStorage.getItem('order_id')
  const executor = localStorage.getItem('id_executor')

  const [newMessage, setNewMessage] = useState<any>()
  const [chats, setChats] = useState<any | null>(null)
  const [tempUuid, setTempUuid] = useState('')

  const messagesEndRef = useRef<null | HTMLDivElement>(null)

  const chat_ids = useParams()

  const [dataChat, setDataChat] = useState<any>([])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
  }

  useEffect(() => {
    scrollToBottom()
    fetch('/chat.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        setDataChat(data.Chat.Orders)
      })
      .catch((error) => {
        console.error('Error fetching the data:', error)
      })
    setTempUuid(`${chat_ids.order_id}-IDProject-${chat_ids.user_id}-IDProvider`)
    const dataId = `${chat_ids.order_id}-IDProject-${chat_ids.user_id}-IDProvider`
    onValue(ref(db, 'Chat/Orders/' + chat_ids.order_id), (snapshot) => {
      setChats([])
      const data = snapshot.val()
      if (data !== null) {
        Object.values(data).map((chat: any) => {
          setChats((oldArray: any) => [...oldArray, chat])
        })
      }
    })
  }, [])

  const getDate = () => {
    const today = new Date()
    const month = today.getMonth() + 1
    const paddedMonth = month < 10 ? '0' + month : month.toString()
    const year = today.getFullYear()
    const date = today.getDate()
    const time = moment().format('HH:mm:ss')
    return `${date}-${paddedMonth}-${year} ${time}`
  }

  const idUser = JSON.parse(localStorage.getItem('id_user') || '{}')
  const executorIDs = JSON.parse(chat_ids.user_id || '{}')
  const executorID = parseInt(executorIDs)

  const NewChat = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: async (values) => {
      setNewMessage(values.message)
      const message = values.message

      ApiService.getChat({
        value: chat_ids.order_id,
      })
        .then((response) => {
          // setTicketsData(response.data)
        })
        .catch((err) => {
          console.log(err)
        })
      setTimeout(() => {
        push(ref(db, `/Chat/Orders/${chat_ids.order_id}`), {
          providerId: chat_ids.user_id,
          seekerId: idUser,
          senderId: idUser,
          message: message,
          timeMessage: getDate(),
          type: 1,
        })
      }, 1000)

      values.message = ''

      ApiService.chatNotification({
        userId: chat_ids.user_id,
        orderId: chat_ids.order_id,
      })
        .then((response) => {
          console.log(response, 'response')
        })
        .catch((err) => {
          console.log(err)
        })
    },
  })

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb title={'المحادثة'} />
        <div className="tcon-about-section m-auto py-20 lg:w-9/12 lg:py-10">
          <div className="rounded-md border">
            <div className="chat-header ">
              <div className="chat-head-user">
                <div className="img">
                  <img id="user_image" alt="" src={ImgDefault} />
                </div>
                <h3>
                  <span id="sender_name">مبدع #{chat_ids.user_id}</span>
                  <span className="badge bg-info" id="sender_id"></span>
                </h3>
              </div>
              <div className="">
                <Link
                  className="bg-primary_color inline-block rounded-md px-8 py-2 text-center text-sm font-semibold leading-7 text-white"
                  to={`/my_orders/${chat_ids.order_id}`}
                >
                  {t('backOrder')}
                </Link>
              </div>
            </div>
            <div className="chat">
              <div className="chat-body">
                {Array.isArray(dataChat) &&
                  dataChat.map((list: any, index: number) => (
                    <Fragment key={index}>
                      {list.orderId === chat_ids.order_id && (
                        <div key={index}>
                          {list.messages.map(
                            (message: any, messageIndex: any) => (
                              <div
                                key={messageIndex}
                                className={
                                  message.senderId !== idUser
                                    ? 'chat-to chat-item px-10'
                                    : 'chat-from chat-item px-10'
                                }
                              >
                                <div>
                                  <div className="img">
                                    <div>
                                      <img
                                        className="sender_image"
                                        alt=""
                                        src={ImgDefault}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    {message.type === 'acceptOrder' && (
                                      <div className="chat-item-text">
                                        {message.message}
                                      </div>
                                    )}
                                    {message.type === 1 && (
                                      <div className="chat-item-text">
                                        {message.message}
                                      </div>
                                    )}
                                    {message.type === 2 && (
                                      <div className="chat-item-text">
                                        <img
                                          src={message.message}
                                          alt={message.timeMessage}
                                        />
                                      </div>
                                    )}
                                    {message.type === 3 && (
                                      <div className="chat-item-text">
                                        <Link
                                          to={message.message}
                                          target="_blank"
                                        >
                                          <p>{message.message}</p>
                                        </Link>
                                      </div>
                                    )}
                                    {message.type === 4 && (
                                      <div className="chat-item-text">
                                        <audio controls>
                                          <source
                                            src={message?.message}
                                            type="audio/mpeg"
                                          />
                                        </audio>
                                      </div>
                                    )}
                                    <div className="chat-time">
                                      {message.sendTime}
                                    </div>
                                  </div>
                                </div>
                                {/* {messageIndex}: {JSON.stringify(message)} */}
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Chat
