import React, {useEffect, useState} from 'react'
import './assets/scss/app.scss'
import NavBar from './routs/NavBar'
import {makeRequest} from './services/data.service'
import {store} from './reducers'
import {Provider} from 'react-redux'


export type ISettings = {
  logo?: string
  website_name?: string
  primary_color?: string
  secondary_color?: string
  activate_color?: string
  google_analytics?: string
}

function App() {
  const lang = localStorage.getItem('site_lang') || 'ar'
  const [settingsData, setSettingsData] = useState<ISettings>()

  useEffect(() => {
    makeRequest
      .ApiService('media/home/settings')
      .then((response: any) => {
        setSettingsData(response.data)
        document.body.style.setProperty(
          '--primary-color',
          response.data.primary_color || '#176775'
        )
        document.body.style.setProperty(
          '--secondary-color',
          response.data.secondary_color || '#AC812D'
        )
        document.body.style.setProperty(
          '--other-color',
          response.data.activate_color || '#38929B'
        )
      })
      .catch((err: any) => {
        console.log(err)
      })
  }, [])

  localStorage.setItem('site_lang', lang)
  localStorage.setItem('i18nextLng', lang)
  if (lang === 'en') {
    document.body.dir = 'ltr'
  } else {
    document.body.dir = 'rtl'
  }
  document.documentElement.lang = lang

  return (
    <Provider store={store}>
      <React.Fragment>
        <NavBar />
      </React.Fragment>
    </Provider>
  )
}

export default App
