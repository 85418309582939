import { Route, Routes } from 'react-router-dom'
import HomePage from "../pages/HomePage/HomePage";
import AboutUs from "../pages/AboutUs/AboutUs";
import ContactUs from '../pages/ContactUs/ContactUs';
import Login from '../auth/Login';
import Verify from '../auth/Verify/Verify';
import Services from '../pages/Services/Services';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import Terms from '../pages/Terms/Terms';
import { PageErrorElement } from '../pages/Errors/Page/Index';
import { ComingSoon } from '../pages/ComingSoon/ComingSoon';
import OrganizationReg from '../auth/OrganizationReg';
import { UserGuide } from '../pages/userGuide/Index';
import CommonOrganizations from '../pages/CommonOrganizations/Index';
import CheckEmail from '../auth/CheckEmail';
import CheckOtp from '../auth/OtpEmail';
import ChangePassword from '../auth/ChangePassword';

const MainRoute = () => {
  return (
    <Routes>
        <Route path={"/"} element={<HomePage />} />
        <Route path={"about-us"} element={<AboutUs />} />
        <Route path={"contact-us"} element={<ContactUs />} />
        <Route path={"login"} element={<Login />} />
        <Route path={"verify"} element={<Verify />} />
        <Route path={"register"} element={<OrganizationReg />} />
        <Route path={"services"} element={<Services />} />
        <Route path={"privacy-policy"} element={<PrivacyPolicy />} />
        <Route path={"terms"} element={<Terms />} />
        <Route path={"userGuide"} element={<UserGuide />} />
        <Route path={"participating-organizations"} element={<CommonOrganizations />} />
        <Route path={"check-email"} element={<CheckEmail />} />
        <Route path={"check-otp"} element={<CheckOtp />} />
        <Route path={"change-password"} element={<ChangePassword />} />
        <Route path={"*"} element={<Login />} />
    </Routes>
  )
}

export default MainRoute