import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ApiService} from '../../services/data.service'
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import {Link} from 'react-router-dom'

export const MyOrders = () => {
  const {t} = useTranslation()
  const [ordersData, setOrdersData] = useState<any | []>()

  useEffect(() => {
    ApiService.MyOrders()
      .then((response) => {
        setOrdersData(response.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const PaginatedItems = () => {
    // const [itemOffset, setItemOffset] = useState(0);
    // const itemsPerPage = 10;

    // const endOffset = itemOffset + itemsPerPage;
    // const currentItems = ordersData && ordersData?.slice(itemOffset, endOffset);
    // const pageCount = Math.ceil(ordersData && ordersData?.length / itemsPerPage);

    // const handlePageClick = (event: any) => {
    //   const newOffset = (event.selected * itemsPerPage) % ordersData?.length;
    //   setItemOffset(newOffset);
    // };

    const howOldIs = (item: any) => {
      localStorage.setItem('order_id', item)
    }

    return (
      <>
        {ordersData &&
          ordersData.map((item: any, index: number) => (
            <Link
              to={
                item.status_id.id === 1 && item.is_media !== true
                  ? `/create_order_step_1/${item.id}`
                  : `/my_orders/${item.id}`
              }
              onClick={() => {
                howOldIs(item.id)
              }}
              className="rw_offer_item"
              key={index}
            >
              <div className="rw_offer_rt">
                <div className="soc_icon">
                  <span>
                    <img
                      src={item.sub_service.mediaImage}
                      alt={item.sub_service.main_service_name}
                    />
                  </span>
                </div>
                <div className="offer_txt_rw">
                  <div className="order_num">#{item.id}</div>
                  <p>
                    {item.sub_service.name} -{' '}
                    {item.sub_service.main_service_name}
                  </p>
                  <ul className="offer_meta clearfix">
                    <li>
                      <i className="fa-regular fa-clock"></i>
                      {item.time}
                    </li>
                    <li>
                      <i className="fa-regular fa-calendar"></i>
                      {item.media_date}
                    </li>
                    {item.count_offer ? (
                      <li>
                        <i className="fa-regular fa-comments"></i>
                        {item.count_offer}
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </div>
              </div>
              <div className="rw_offer_lt ms-auto">
                <div className="rw_offer_lt_cn">
                  <div
                    className={
                      item.status_id.id === 3
                        ? 'state_order order-blue'
                        : item.status_id.id === 14
                        ? 'state_order order-gray'
                        : item.status_id.id === 7
                        ? 'state_order order-blue'
                        : item.status_id.id === 10
                        ? 'state_order order-red'
                        : 'state_order'
                    }
                  >
                    {item.status_id.name}
                  </div>
                  {/* <div className="sale_pay">
                    <span>{item?.total_price}</span>
                    {t('rs')}
                  </div> */}
                </div>
              </div>
            </Link>
          ))}
        {/* <ReactPaginate
          previousLabel={t('previous')}
          nextLabel={t('next')}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeClassName="active"
        /> */}
      </>
    )
  }

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb title={t('myorders')} />
        <div className="tcon-about-section py-20">
          <div className="rounded-md border">
            {ordersData?.length !== 0 ? (
              <PaginatedItems />
            ) : (
              <div className="flex flex-col justify-center items-center p-6">
                <p>لا يوجد لديك طلبات</p>
                <Link
                  className="bg-primary_color mt-6 inline-block w-1/2 rounded-md px-8 py-2 text-center text-sm font-semibold leading-7 text-white lg:mt-6 lg:w-1/6"
                  to={'/services'}
                >
                  {t("myservices")}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default MyOrders
