import React, {useEffect, useState} from 'react'
import { ApiService, makeRequest } from '../../services/data.service';
import { AboutItem } from './AboutType';


export const AboutUs = () => {
  const [aboutData, setAboutData] = useState<AboutItem>();

  useEffect(() => {
    makeRequest.ApiService('media/home')
    .then((response: any) => {
      setAboutData(response.data?.idea_section);
    })
    .catch((err: any) => {
      console.log(err)
    })
  }, [])

  return (
    <React.Fragment>
        <div className="tcon-about-section py-40">
            <div className="relative xl:container isolate mx-auto">
                <div className="flex flex-wrap justify-between p-6 lg:px-8 items-center">
                    <div className="flex-2 lg:flex-1 w-100 lg:w-50 items-center tcon-about-img">
                        <img className="pe-0 lg:pe-8 max-w-xs lg:max-w-xl mx-auto" src={aboutData && aboutData.image} alt='' />
                    </div>
                    <div className="flex-1 w-100 lg:w-50 mt-10 lg:mt-0 md:mr-5">
                        <div className="text-start">
                            <h1 className="text-3xl font-bold tracking-tight sm:text-6xl">{aboutData && aboutData.title}</h1>
                            <p className="mt-6 text-2xl leading-8">{aboutData && aboutData.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}
