import React from 'react'
import ReactDOM from 'react-dom/client'
import {hydrate, render} from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import i18n from './i18n'
import './i18n'
import App from './App'
import {I18nextProvider} from 'react-i18next'
import './assets/scss/index.scss'

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );

const rootElement = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
rootElement.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </BrowserRouter>
);

// render(
//   <BrowserRouter>
//     <I18nextProvider i18n={i18n}>
//       <App />
//     </I18nextProvider>
//   </BrowserRouter>,
//   rootElement
// )

// if (rootElement?.hasChildNodes()) {
//   render(
//     <BrowserRouter>
//       <I18nextProvider i18n={i18n}>
//         <App />
//       </I18nextProvider>
//     </BrowserRouter>,
//     rootElement
//   )
// } else {
//   hydrate(
//     <BrowserRouter>
//       <I18nextProvider i18n={i18n}>
//         <App />
//       </I18nextProvider>
//     </BrowserRouter>,
//     rootElement
//   )
// }

// root.render(
//   <BrowserRouter>
//     <I18nextProvider i18n={i18n}>
//     <App />
//     </I18nextProvider>
//   </BrowserRouter>
// );