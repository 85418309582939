import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export const PageErrorElement = () => {
  const {t} = useTranslation()
  return (
    <>
      <div className="fixed start-0 top-0 flex h-full w-full items-center justify-center bg-white p-4 text-center">
        <div>
          <h2 className="text-5xl font-bold text-green-500 lg:text-8xl">404</h2>
          <p className="mt-4 text-lg font-medium">هذه الصفحة غير متوفرة !!</p>
          <Link
            to={'/'}
            className="bg-primary_color mt-5 inline-block rounded-md px-8 py-2 text-center text-sm font-semibold leading-7 text-white"
          >
            {t('backtohome')}
          </Link>
        </div>
      </div>
    </>
  )
}
