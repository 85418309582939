import React, {useEffect, useState} from 'react'
import {makeRequest} from '../../services/data.service'
import {AboutItem} from './AboutType'
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import {Impact} from '../../components/Impact/Impact'
import {useTranslation} from 'react-i18next'

export const AboutUs = () => {
  const {t} = useTranslation()
  const [aboutData, setAboutData] = useState<AboutItem>()

  useEffect(() => {
    makeRequest.ApiService('media/home')
    .then((response: any) => {
      setAboutData(response.data?.idea_section);
    })
    .catch((err: any) => {
      console.log(err)
    })
  }, [])

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb title={t('about')} />
        
        <div className="tcon-about-section py-40">
          <div className="relative isolate mx-auto xl:container">
            <div className="flex flex-wrap items-start items-center justify-between p-6 lg:px-8">
              <div className="flex-2 w-100 lg:w-50 tcon-about-img items-center lg:flex-1">
                <img
                  className="mx-auto max-w-xs pe-0 lg:max-w-xl lg:pe-8"
                  src={aboutData && aboutData.image}
                  alt=""
                />
              </div>
              <div className="w-100 lg:w-50 mt-10 flex-1 md:mr-5 lg:mt-0">
                <div className="text-start">
                  <h1 className="text-3xl font-bold tracking-tight sm:text-6xl">
                    {aboutData && aboutData.title}
                  </h1>
                  <p className="mt-6 text-2xl leading-8">
                    {aboutData && aboutData.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`tcon-why-us-wrapper py-15 sm:py-32`}>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {t('vision2030')}
            </h2>
          </div>
          <div className="mx-auto my-16 lg:mt-16">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 md:max-w-none md:grid-cols-3 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
              <div className="relative rounded-lg p-5 text-center">
                <dt className="text-center text-xl font-semibold leading-7 text-white">
                  {t('vision2030one')}
                </dt>
              </div>
              <div className="relative rounded-lg p-5 text-center">
                <dt className="text-center text-xl font-semibold leading-7 text-white">
                  {t('vision2030tow')}
                </dt>
              </div>
              <div className="relative rounded-lg p-5 text-center">
                <dt className="text-center text-xl font-semibold leading-7 text-white">
                  {t('vision2030three')}
                </dt>
              </div>
              <div className="relative rounded-lg p-5 text-center">
                <dt className="text-center text-xl font-semibold leading-7 text-white">
                  {t('vision2030four')}
                </dt>
              </div>
              <div className="relative rounded-lg p-5 text-center">
                <dt className="text-center text-xl font-semibold leading-7 text-white">
                  {t('vision2030five')}
                </dt>
              </div>
              <div className="relative rounded-lg p-5 text-center">
                <dt className="text-center text-xl font-semibold leading-7 text-white">
                  {t('vision2030six')}
                </dt>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="bg-white py-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t('platformobjectives')}
            </h2>
          </div>
          <div className="mx-auto my-16 lg:mt-24">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 md:max-w-none md:grid-cols-2 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
              <div className="relative rounded-lg p-5 pl-20 shadow-lg">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  {t('platformobjectivesone')}
                </dt>
              </div>
              <div className="relative rounded-lg p-5 pl-20 shadow-lg">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  {t('platformobjectivestow')}
                </dt>
              </div>
              <div className="relative rounded-lg p-5 pl-20 shadow-lg">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  {t('platformobjectivesthree')}
                </dt>
              </div>
              <div className="relative rounded-lg p-5 pl-20 shadow-lg">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  {t('platformobjectivesfour')}
                </dt>
              </div>
              <div className="relative rounded-lg p-5 pl-20 shadow-lg">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  {t('platformobjectivesfive')}
                </dt>
              </div>
              <div className="relative rounded-lg p-5 pl-20 shadow-lg">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  {t('platformobjectivessix')}
                </dt>
              </div>
              <div className="relative rounded-lg p-5 pl-20 shadow-lg">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  {t('platformobjectiveseven')}
                </dt>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <Impact className={'tcon-why-us-wrapper'} />

      <Footer />
    </div>
  )
}

export default AboutUs
