import React, {useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {Bounce, ToastContainer, toast} from 'react-toastify'
import axios from 'axios'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import {persistMyInfo} from '../services/persistence'
import HeaderPages from '../components/header/HeaderPages'
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import {Footer} from '../components/Footer/Footer'
import verifyImg from '../assets/images/sm-login.png'
import thecontentlogo from '../assets/images/thecontentlogo.svg'

export const Login = () => {
  const {t} = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()

  const [loginData, setLoginData] = useState<any>()

  const [success, setSuccess] = useState(false)

  const fcm_token = localStorage.getItem('fcm_token')
  const lang = localStorage.getItem('site_lang') || 'ar'

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email(t('emailerror')).required(t('emailerror')),
    password: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  })

  const getAuthHeader = () => ({
    'Accept-Language': lang,
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'content-type': 'multipart/form-data',
  })

  const Login = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      password: '',
      message: '',
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      axios
        .post(
          'https://media.thecontentapp.net/dashboard/api/media/auth/login',
          {
            email: values.email,
            password: values.password,
            device: 'web',
            phone_code: '966',
            fcm_token: fcm_token,
          },
          {headers: getAuthHeader()}
        )
        .then((response: any) => {
          if (response.status !== false && response.status !== 404) {
            persistMyInfo(response.data)
            setTimeout(() => {
              navigate(location.pathname)
              window.location.reload()
            }, 500)
          } else {
            toast.error(response.msg, {
              position: 'top-center',
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
              transition: Bounce,
            })
            setLoginData(response)
            setSuccess(true)
          }
        })
        .catch((err) => {
          console.log(err)
          toast.error(err.data.msg, {
            position: 'top-center',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Bounce,
          })
        })
    },
  })

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mt-20 sm:mx-auto">
        <Breadcrumb title={t('log')} />
        <div className="tcon-about-section m-auto py-5 md:w-11/12 lg:w-11/12 lg:py-8">
          <div className="relative isolate mx-auto xl:container">
            <form onSubmit={Login.handleSubmit}>
              <div className="space-y-12">
                <div className="rounded-xl border-gray-900/10 bg-slate-100 p-5 pb-12 lg:p-16">
                  <h2 className="text-center text-xl font-semibold leading-7 text-gray-900">
                    {t('log')}
                  </h2>

                  <div className="flex flex-wrap items-center justify-between p-6 lg:px-8">
                    <div className="flex-2 lg:w-50 w-full items-center lg:flex-1">
                      <div className="item-center">
                        <div className="m-auto my-8">
                          <label
                            htmlFor="phone"
                            className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                          >
                            {t('email')}
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            onChange={Login.handleChange}
                            onBlur={Login.handleBlur}
                            value={Login.values.email}
                            placeholder={t('email')}
                            autoComplete="family-name"
                            className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {Login.errors.email && Login.touched.email && (
                            <div className="text-sm text-red-600">
                              {Login.errors.email}
                            </div>
                          )}
                        </div>
                        <div className="m-auto my-8">
                          <label
                            htmlFor="phone"
                            className="mb-2 block text-start text-sm font-medium leading-6 text-gray-900"
                          >
                            {t('password')}
                          </label>
                          <input
                            type="password"
                            name="password"
                            id="password"
                            onChange={Login.handleChange}
                            onBlur={Login.handleBlur}
                            value={Login.values.password}
                            placeholder="*****************"
                            autoComplete="family-name"
                            className="mb-3 block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {Login.errors.password && Login.touched.password && (
                            <div className="text-sm text-red-600">
                              {Login.errors.password}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="item-center col-span-full m-auto flex items-center justify-between gap-5 flex-wrap lg:flex-nowrap">
                        <button
                          type="submit"
                          className={
                            Login.values.email === ''
                              ? 'bg-primary_color rounded-md bg-indigo-600 px-24 py-5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-75'
                              : 'bg-primary_color rounded-md bg-indigo-600 px-24 py-5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          }
                        >
                          {t('loginuser')}
                        </button>

                        <Link
                          to="/register"
                          className=" hover:text-primary_color text-sm font-semibold text-black underline"
                        >
                          {t('register')}
                        </Link>
                        <Link
                          to="/check-email"
                          className=" hover:text-primary_color text-sm font-semibold text-black underline"
                        >
                          {t('rememberPassword')}
                        </Link>
                      </div>

                      <div className="item-center col-span-full m-auto mt-12 flex items-center justify-center">
                        <Link
                          to="https://thecontentapp.net/auth"
                          target="_blank"
                          className="hover:text-primary_color flex text-sm font-semibold text-black underline"
                        >
                          {t('registerSite')}
                          <img
                            src={thecontentlogo}
                            alt="The Content"
                            className="ms-4 w-20"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="flex-2 w-100 lg:w-50 items-center lg:flex-1">
                      <img className="pr-20" src={verifyImg} alt="verify" />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  )
}

export default Login
