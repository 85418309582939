import React, {Fragment, useRef, useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {useTranslation} from 'react-i18next'
import {Link, NavLink, useNavigate, useParams} from 'react-router-dom'
import {ApiService} from '../../services/data.service'
import {unpersistMyInfo} from '../../services/persistence'
import {store} from '../../reducers'
import {getTokenvalue, onMessageListener} from '../../services/firebase'
import {Notification} from './Notification'
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher'

export interface IHeaderProps {
  text?: string
}

export type IHeaderSettings = {
  logo?: string
  website_name?: string
  primary_color: string
  secondary_color: string
  activate_color: string
}

export type IUserData = {
  name?: string
  first_name?: string
  email?: string
}

const HeaderPages = (props: IHeaderProps) => {
  const {t, i18n} = useTranslation()

  const user_id = store.getState().authStoreState
  const lang = localStorage.getItem('site_lang')

  const [settingsData, setSettingsData] = useState<IHeaderSettings>()
  const [userData, setUserData] = useState<IUserData>()
  const [chatData, setChatData] = useState<any>()
  const [notiData, setNotiData] = useState<any>()
  const [showMenu, setShowMenu] = useState(false)
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [isTokenFound, setTokenFound] = useState(false)
  // const [serviceData, setServiceData] = useState(false)
  const [show, setShow] = useState(false)
  const [notification, setNotification] = useState({title: '', body: ''})
  const [activeSection, setActiveSection] = useState('section-1')

  const userMenuRef = useRef(null)

  // const pathName = window.location.pathname
  getTokenvalue(setTokenFound)

  const order_id = useParams()

  onMessageListener()
    .then((payload) => {
      setShow(true)
      setNotiData(payload)
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      })
      if (payload?.data.type === 'chatRoom') {
        ApiService.getChatProfileProvider({
          room_id: payload?.data.room_id,
        })
          .then((response) => {
            setChatData(response.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
    .catch((err) => console.log('failed: ', err))

  const handleShowMenu = () => {
    setShowMenu(!showMenu)
  }
  const handleShowUserMenu = () => {
    setShowUserMenu(!showUserMenu)
  }
  // Function to handle click outside of menu
  const handleClickOutside = (event) => {
    if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
      setShowUserMenu(false) // Close menu if clicked outside
    }
  }

  const handleCloseMenu = () => {
    setShowMenu(!showMenu)
  }

  const isAuthenticated = store.getState().authStoreState.isAuthenticated
  const navigate = useNavigate()

  const logout = () => {
    unpersistMyInfo()
    setTimeout(() => {
      navigate('/')
      window.location.reload()
    }, 200)
  }

  const handleLinkClick = (section: React.SetStateAction<string>) => {
    setActiveSection(section)
    setTimeout(() => {
      scrollToSection(section)
    }, 1500)
  }

  const scrollToSection = (section: any) => {
    const element = document.getElementById(section)
    element?.scrollIntoView({behavior: 'smooth'})
  }

  useEffect(() => {
    if(isAuthenticated) {

      ApiService.getProfile()
        .then((response) => {
          setUserData(response.data)
          if (response.data.status !== 'active') {
            logout()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    ApiService.Settings()
      .then((response) => {
        setTimeout(() => {
          setSettingsData(response.data)
        }, 200)
      })
      .catch((err) => {
        console.log(err)
      })

    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const sections = document.querySelectorAll('section')

      sections.forEach((section) => {
        const sectionTop = section.offsetTop
        const sectionHeight = section.offsetHeight

        if (
          scrollPosition >= sectionTop - 50 && // Adjust the offset as needed
          scrollPosition < sectionTop + sectionHeight - 50 // Adjust the offset as needed
        ) {
          setActiveSection(section.id)
        }
      })
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // Effect to add click event listener when menu is shown
  useEffect(() => {
    if (showUserMenu) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showUserMenu])

  useEffect(() => {
    const handleUrlAnchorChange = () => {
      const sectionFromUrl = window.location.hash.substr(1)
      setActiveSection(sectionFromUrl)
    }

    window.addEventListener('hashchange', handleUrlAnchorChange)
    return () => {
      window.removeEventListener('hashchange', handleUrlAnchorChange)
    }
  }, [])

  const handleOpenNotification = () => {
    if (notiData?.type === 'ticket_reply') {
      navigate(`/tickets/${notiData?.order_id}/${notiData?.complaint_id}`)
      window.location.reload()
    }
    if (notiData?.type === 'chatRoom') {
      navigate(`/chat/${chatData?.order_id}/${chatData?.provider_id}`)
    }
    if (notiData?.type === 'order') {
      navigate(`/my_orders/${notiData?.order_id}`)
    }
  }
  const handleCloseNotification = () => {
    setShow(false)
  }

  return (
    <React.Fragment>
      <div className="alert bg-[#faebd7] p-3 text-center text-[#ab730b]">
        <p> إطلاق تجريبي</p>
      </div>
      <header className="tcon-header-pages relative inset-x-0 top-0">
        {show && (
          <div
            id="toast-message-cta"
            className="fixed right-4 top-4 z-50 w-full max-w-xs rounded-lg bg-white p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400"
            role="alert"
          >
            <div className="flex items-center justify-between">
              <div
                className="flex w-64 items-center"
                onClick={handleOpenNotification}
              >
                <svg
                  className="h-4 w-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.147 15.085a7.159 7.159 0 0 1-6.189 3.307A6.713 6.713 0 0 1 3.1 15.444c-2.679-4.513.287-8.737.888-9.548A4.373 4.373 0 0 0 5 1.608c1.287.953 6.445 3.218 5.537 10.5 1.5-1.122 2.706-3.01 2.853-6.14 1.433 1.049 3.993 5.395 1.757 9.117Z"
                  />
                </svg>
                <div className="mx-3 w-64 text-sm font-normal">
                  <span className="mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                    {notification?.title}
                  </span>
                </div>
              </div>
              <button
                type="button"
                className="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg bg-white p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={handleCloseNotification}
              >
                <span className="sr-only">Close</span>
                <svg
                  className="h-3 w-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
        <div className="container mx-auto">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-20 w-auto"
                  src={settingsData && settingsData.logo}
                  alt={settingsData && settingsData.website_name}
                />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                onClick={handleShowMenu}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {isAuthenticated ? (
                <Fragment>
                  <NavLink
                    to="/"
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    {t('home')}
                  </NavLink>

                  <NavLink
                    to="/services"
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    {t('myservices')}
                  </NavLink>
                  <NavLink
                    to="/my_orders"
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    {t('myorders')}
                  </NavLink>
                  <NavLink
                    to="/participating-organizations"
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    {t('commonOrganizations')}
                  </NavLink>
                  <NavLink
                    to="/contact-us"
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    {t('contact')}
                  </NavLink>
                  <NavLink
                    to="/terms"
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    {t('terms')}
                  </NavLink>
                </Fragment>
              ) : (
                <Fragment>
                  <NavLink
                    to="/"
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    {t('home')}
                  </NavLink>
                  <NavLink
                    to="/"
                    className={({isActive, isPending}) =>
                      isPending
                        ? 'pending text-sm font-semibold leading-6 text-white'
                        : isActive
                        ? 'activelink text-sm font-semibold leading-6 text-white'
                        : 'text-sm font-semibold leading-6 text-white'
                    }
                    // className="text-sm font-semibold leading-6 text-white"
                    onClick={() => handleLinkClick('services')}
                  >
                    {t('services')}
                  </NavLink>
                  <NavLink
                    to="/participating-organizations"
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    {t('commonOrganizations')}
                  </NavLink>
                  <NavLink
                    to="/contact-us"
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    {t('contact')}
                  </NavLink>
                  {/* <NavLink
                    to="/"
                    className={({isActive, isPending}) =>
                      isPending
                        ? 'pending text-sm font-semibold leading-6 text-white'
                        : isActive
                        ? 'activelink text-sm font-semibold leading-6 text-white'
                        : 'text-sm font-semibold leading-6 text-white'
                    }
                    onClick={() => handleLinkClick('whysm')}
                  >
                    {t('whysm')}
                  </NavLink> */}
                  <NavLink
                    to="/terms"
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    {t('terms')}
                  </NavLink>
                </Fragment>
              )}
            </div>
            <div className="hidden items-center lg:flex lg:flex-1 lg:justify-end">
              {isAuthenticated ? (
                <Fragment>
                  <Notification />
                  <div className="relative inline-block text-left">
                    <div
                      className="me-3 flex cursor-pointer items-center rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={handleShowUserMenu}
                    >
                      <p className="w-32 overflow-hidden whitespace-nowrap">
                        {' '}
                        {userData && userData.name
                          ? userData.name
                          : userData && userData.name}
                      </p>
                      <i className="fa-solid fa-user ms-3"></i>
                    </div>
                    <div
                      ref={userMenuRef}
                      className={
                        showUserMenu
                          ? 'active absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white opacity-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                          : 'absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white opacity-0 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                      }
                    >
                      <ul
                        className="px-5 py-5 text-center text-sm text-gray-700"
                        aria-labelledby="dropdownDefaultButton"
                      >
                        <li className="mb-2">
                          <NavLink to="/profile">{t('profile')}</NavLink>
                        </li>
                        <li>
                          <Link onClick={logout} to="">
                            {t('signout')}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <NavLink
                  to="/login"
                  className={`me-3 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-[${
                    settingsData && settingsData.activate_color
                  }]`}
                >
                  {t('login')}
                </NavLink>
              )}

              <LanguageSwitcher />
            </div>
          </nav>
          {/* Mobile Menu */}
          <div
            className={
              showMenu
                ? 'hksa-sidemenu active lg:hidden'
                : 'hksa-sidemenu lg:hidden'
            }
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 z-50"></div>
            <div className="side-bar fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <Link to="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-20 w-auto"
                    src={settingsData && settingsData.logo}
                    alt={settingsData && settingsData.website_name}
                  />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-white"
                  onClick={handleCloseMenu}
                >
                  <span className="sr-only">Close menu</span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {isAuthenticated ? (
                      <Fragment>
                        <NavLink
                          to="/"
                          className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          {t('home')}
                        </NavLink>

                        <NavLink
                          to="/services"
                          className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          {t('myservices')}
                        </NavLink>
                        <NavLink
                          to="/my_orders"
                          className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          {t('myorders')}
                        </NavLink>
                        <NavLink
                          to="/participating-organizations"
                          className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          {t('commonOrganizations')}
                        </NavLink>
                        <NavLink
                          to="/contact-us"
                          className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          {t('contact')}
                        </NavLink>
                        <NavLink
                          to="/terms"
                          className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          {t('terms')}
                        </NavLink>
                        <NavLink
                          to="/profile"
                          className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          {t('profile')}
                        </NavLink>
                        <div
                          onClick={logout}
                          className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          {t('signout')}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <NavLink
                          to="/"
                          className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          {t('home')}
                        </NavLink>
                        <NavLink
                          to="/"
                          className={({isActive, isPending}) =>
                            isPending
                              ? 'pending mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black'
                              : isActive
                              ? 'activelink mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black'
                              : 'mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black'
                          }
                          // className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                          onClick={() => handleLinkClick('services')}
                        >
                          {t('services')}
                        </NavLink>
                        <NavLink
                          to="/participating-organizations"
                          className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          {t('commonOrganizations')}
                        </NavLink>
                        <NavLink
                          to="/contact-us"
                          className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          {t('contact')}
                        </NavLink>
                        <NavLink
                          to="/terms"
                          className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          {t('terms')}
                        </NavLink>
                        <NavLink
                          to={'/login'}
                          className="mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          {t('login')}
                        </NavLink>
                      </Fragment>
                    )}
                  </div>
                  <div className="py-6">
                    {lang === 'en' && (
                      <button
                        type="button"
                        onClick={() => {
                          localStorage.setItem('site_lang', 'ar')
                          i18n.changeLanguage('ar')
                        }}
                        className="btn btn-lang text-white"
                      >
                        AR
                      </button>
                    )}
                    {lang === 'ar' && (
                      <button
                        type="button"
                        onClick={() => {
                          localStorage.setItem('site_lang', 'en')
                          i18n.changeLanguage('en')
                        }}
                        className="btn btn-lang text-white"
                      >
                        EN
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default withTranslation()(HeaderPages)
