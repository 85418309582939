import React from 'react'
import {useTranslation} from 'react-i18next'

const LanguageSwitcher = () => {
  const {i18n} = useTranslation()

  const lang = localStorage.getItem('site_lang')

  const changeLanguage = (lng: any) => {
    localStorage.setItem('site_lang', lng)
    window.location.reload();
    // i18n.changeLanguage(lng)
  }

  return (
    <div>
      {lang === 'en' && (
        <button
          type="button"
          onClick={() => changeLanguage('ar')}
          className="btn btn-lang text-white"
        >
          العربية
        </button>
      )}
      {lang === 'ar' && (
        <button
          type="button"
          onClick={() => changeLanguage('en')}
          className="btn btn-lang text-white"
        >
          English
        </button>
      )}
    </div>
  )
}

export default LanguageSwitcher
