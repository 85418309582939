import React, {Fragment, useEffect, useState} from 'react'
import notificationImg from '../../assets/images/notification.png'
import {ApiService} from '../../services/data.service'
import {Link} from 'react-router-dom'

export const Notification = () => {
  const [notification, setNotification] = useState<any>([])
  const [showUserMenu, setShowUserMenu] = useState(false)

  useEffect(() => {
    ApiService.getNotification()
      .then((response) => {
        setNotification(response.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => {
          setShowUserMenu(!showUserMenu)
        }}
        className="btn btn-lang text-white"
      >
        <img className="mx-4 h-6 w-6" src={notificationImg} alt="" />
      </button>
      {showUserMenu && (
        <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {notification.length !== 0 ? (
            <ul className="bg-white px-5 py-5 text-start text-sm text-gray-700 dark:text-gray-200">
              {notification &&
                notification.map((item: any, index: number) => (
                  <li className="mb-2 border-b-2 py-2 last-of-type:border-b-0">
                    <Link
                      to={
                        item.type === 'chatRoom'
                          ? `/chat/${item.order_id}/${item.provider_id}`
                          : item.type === 'order'
                          ? `/my_orders/${item.order_id}`
                          : ''
                      }
                      onClick={() => {
                        localStorage.setItem('id_executor', item.provider_id)
                        localStorage.setItem('order_id', item.order_id)
                        setTimeout(() => {
                          window.location.reload()
                        }, 500)
                      }}
                      key={index}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
            </ul>
          ) : (
            <p>لا يوجد إشعارات</p>
          )}
        </div>
      )}
    </div>
  )
}
