import React, { ChangeEvent } from 'react';

interface QuantityInputProps {
  value: number;
  onChange: (value: number) => void;
}

const QuantityInput: React.FC<QuantityInputProps> = ({ value, onChange }) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue)) {
      onChange(newValue);
    }
  };

  const handleIncrement = () => {
    onChange(value + 1);
  };

  const handleDecrement = () => {
    if (value > 1) {
      onChange(value - 1);
    }
  };

  return (
    <div className="flex items-center">
      <button
        onClick={handleDecrement}
        className="px-3 py-2.5 bg-gray-200 text-gray-700 hover:bg-gray-300 rounded-s"
      >
        -
      </button>
      <input
        type="number"
        className="block w-14 border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        value={value}
        onChange={handleInputChange}
      />
      <button
        onClick={handleIncrement}
        className="px-3 py-2.5 bg-gray-200 text-gray-700 hover:bg-gray-300 rounded-e"
      >
        +
      </button>
    </div>
  );
};

export default QuantityInput;