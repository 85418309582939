import React, { Fragment } from "react";
import { BrowserRouter } from "react-router-dom";
import { store } from '../reducers';
import AuthRoute from "./AuthRoute";
import MainRoute from "./MainRoute";

const NavBar = () => {

  const userDate = store.getState().authStoreState.isAuthenticated;
  const isAuthenticated = localStorage.getItem('isAuthenticated') ;
  
  return (
    <React.Fragment>
        {userDate
          ? <AuthRoute />
          : <MainRoute />
        }
    </React.Fragment>
  );
};

export default NavBar;
