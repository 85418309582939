import React, {useEffect, useState} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import {ApiService, makeRequest} from '../../services/data.service'
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next';


export const Clients = () => {

  const {t} = useTranslation();
  const [agentsData, setAgentsData] = useState<any>()

  
  useEffect(() => {
    makeRequest
    .ApiService('media/home')
    .then((response: any) => {
      setAgentsData(response.data?.clients)
    })
    .catch((err: any) => {
      console.log(err)
    })
  }, [])

  return (
    <div className="tcon-clients-wrapper py-6 px-4">
      <div className="container mx-auto max-w-7xl">
        <div className="row">
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('partners')}</h2>
            </div>
          <Swiper
            spaceBetween={30}
            slidesPerView={5}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            breakpoints={{
              360: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              500: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              800: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 4,
              },
            }}
            className="mx-auto my-16 lg:mt-16"
          >
            {agentsData &&
              agentsData.map((item: any, index: number) => (
                <SwiperSlide key={index} virtualIndex={index}>
                  <img className="h-16" src={item.image} alt={item.title} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}
